import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../layout/Footer";
import Header from "../layout/Header.";

const ClaimToken = () => {
  return (
    <>
      <Header />
      <section className="py-5 my-5">
        <Container>
          <Row>
            <Col>
              <h1 className="fw-700 font-outfit text-white text-center">
                Comming Soon
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default ClaimToken;
