import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Footer from "../layout/Footer";
import S1 from "../assets/images/ser-education.png";
import S2 from "../assets/images/ser-financial.png";
import S3 from "../assets/images/ser-Medical.png";
import Header from "../layout/Header.";
const Service = () => {
  return (
    <>
      <Header />
      <section className="text-center pt-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={12} lg={8}>
              <h5 className="font-outfit fw-700 text-white text-capitalize">
                Services
              </h5>
              <h2 className="font-outfit fw-700 text-white my-3 my-md-3">
                A lot of people have fancy things to say about customer service,
                but it’s just a day-in, day-out, ongoing, never-ending,
                persevering, compassionate kind of activity.
              </h2>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify mb-4"></p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sect-title-emplty-sec"></section>
      <section className="pb-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={12} lg={6} className="px-3 px-md-5">
              <Image src={S1} className="img-fluid mb-4 mb-md-0" />
            </Col>
            <Col md={12} lg={6}>
              <h5 className="font-outfit fw-700 text-white text-capitalize">
                Education
              </h5>
              <h2 className="font-outfit fw-700 text-white my-3 my-md-4">
                GKC Blockchain’s for Education sector
              </h2>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify">
                GKC Blockchain will be extremely beneficial in the education
                sector. It allows educational institutions to secure the data of
                their students. They are able to take ownership of their
                credentials, awards, certificates, and academic identity.
              </p>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify">
                GKC Blockchain technology can offer universal access to open
                educational resources like books, podcasts, and movies in the
                public domain, and are free to use and redistribute, in addition
                to supporting lifelong learning and enables the affordable and
                secure sharing of these resources in a public network. Educators
                have also found the technology helpful for creating smart
                contracts, distributing student loan payments and sharing crypto
                incentives.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-0 py-md-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={12} lg={6} className="order-1 order-md-1 order-lg-0">
              <h5 className="font-outfit fw-700 text-white text-capitalize">
                Medical
              </h5>
              <h2 className="font-outfit fw-700 text-white my-3 my-md-4">
                GKC Blockchain’s for Medical sector
              </h2>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify">
                GKC will provide decision making as blockchain allows several
                doctors from different location to view the same data in real
                time. Transforms patient medical records to a decentralized
                system that can't be tampered with speeds up medical
                credentialing. KKC Blockchain's distributed ledger technology
                facilitates that manages the medicine supply chain and helps
                healthcare researchers unlock genetic code. GKC will make
                platform globally for pharma companies to use this technology,
                even some Pharma companies are looking to streamline the process
                of developing and testing new drugs by using blockchain
                technology. The companies believe the collaboration with
                blockchain technology may help to increase efficiency,
                accelerate the R&D process, and reduce drug development costs.
              </p>
            </Col>
            <Col
              md={12}
              lg={6}
              className="order-0 order-md-0 order-lg-1 px-3 px-md-5"
            >
              <Image src={S3} className="img-fluid mb-4 mb-md-0" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={12} lg={6} className="px-3 px-md-5">
              <Image src={S2} className="img-fluid mb-4 mb-md-0" />
            </Col>
            <Col md={12} lg={6}>
              <h5 className="font-outfit fw-700 text-white text-capitalize">
                Financial
              </h5>
              <h2 className="font-outfit fw-700 text-white my-3 my-md-4">
                GKC Blockchain’s for Financial sector
              </h2>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify">
                GKC will provide services in financial sector and global capital
                markets to manage credit score, records of financial
                transactions, ledger for payments, banking and lending services,
                Crypto trading in exchanges, Crypto assets in digital wallet
                etc.
              </p>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify">
                In future blockchain technology could facilitate faster payments
                at lower fees than banks and it will be easier to check
                transaction records. Blockchain technology is extremely secure.
                Because Blockchain papers cannot be changed or withdrawn,
                Fintechs can more easily prevent fraudulent activities.
              </p>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify">
                GKC will be acceptable in future as a utility, payment, and
                stable coins.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-footer-upsec"></section>
      <Footer />
    </>
  );
};

export default Service;
