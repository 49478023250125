import React from "react";

const AuthLayout = (props) => {
  const { children } = props;
  return (
    <>
      <section className="signup-layout">{children}</section>
    </>
  );
};

export default AuthLayout;
