import React from "react"
import { Image, Nav } from "react-bootstrap"
import { NavLink, Link } from "react-router-dom"
import Logo from "../../assets/images/GKC_Logo.png"
import { BsGrid1X2Fill } from "react-icons/bs"
import { MdAccountBalanceWallet } from "react-icons/md"
import { HiPaperAirplane } from "react-icons/hi"
import { TiUser } from "react-icons/ti"
import { FaUsers } from "react-icons/fa"
import { IoMdSettings } from "react-icons/io"

const AdminSidebar = () => {
  return (
    <>
      <aside className="user-dash-siedebar">
        <div className="text-center">
          <Image src={Logo} className="img-fluid mb-5" alt="" />
        </div>
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavLink
            to="/admin-dashboard"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            <BsGrid1X2Fill className="me-2" /> Dashboard
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to="/users"
          >
            <FaUsers className="me-2" /> User's
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to="/direct-transfer"
          >
            <FaUsers className="me-2" /> Direct Transfer
          </NavLink>
          <a className="nav-link" href="/admin-dashboard#BuyGKC_token">
            <MdAccountBalanceWallet className="me-2" /> Buy
          </a>
          <Link className="nav-link">
            <HiPaperAirplane className="me-2" /> Airdrop
          </Link>
          {/* <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to="/admin-account-setting"
          >
            <IoMdSettings className="me-2" /> Account Setting
          </NavLink> */}
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to="/admin-profile"
          >
            <TiUser className="me-2" /> My Profile
          </NavLink>

        </Nav>
        <div className="p-5"></div>
      </aside>
    </>
  )
}

export default AdminSidebar
