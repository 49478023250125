import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import AboutCard from "../components/about/AboutCard";
import Header from "../layout/Header.";
import AboutImage from "../assets/images/aboutimage.png";
import Aboutdecent from "../assets/images/about-decentrl.png";
import aboufooter from "../assets/images/aboufooter.png";
import Footer from "../layout/Footer";
import RoadmapVertical from "../components/about/RoadmapVertical";
import Full1 from "../assets/images/full-one.png";
import Full2 from "../assets/images/full-two.png";
import Full3 from "../assets/images/full-three.png";
import Full4 from "../assets/images/full-four.png";
import IntroVideo from "../assets/images/cards-video.webm";
import AboutVideo from "../components/about/AboutVideo";
import RoadMap2 from "../components/home/RoadMap2";
const About = () => {
  return (
    <>
      <Header />
      <section className="text-center pt-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={12} lg={10}>
              <h5 className="font-outfit fw-700 text-white text-capitalize">
                About
              </h5>
              <h2 className="font-outfit fw-700 text-white my-3 my-md-3">
                Using blockchain technology, Smart Contracts, and our
                cryptocurrency, GKC Provides
              </h2>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify mb-4">
                The infrastructure for digital health applications and services
                to be built. These applications and services will be seamlessly
                powered by the user’s health data. Anyone will be able to
                develop on GKC ’s platform and we hope to foster a thriving
                ecosystem to provide value, reduce costs, and ultimately improve
                people’s lives.Many have tried to overcome this issue, and it is
                high on the agenda of governments and a source of frustration
                for doctors and patients. A significant component of the
                challenge focuses on data security.
              </p>

              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify mb-4">
                {" "}
                The cryptocurrency revolution is taking over the world, with
                many sectors adapting to the new currency method. From real
                estate to business, medical, education, and financial sector,
                you can use cryptocurrencies to pay for services from some
                companies. It will be interesting to see how soon this trend
                catches up with other sectors of the economy and it will live up
                to its potential to transform the financial world.
              </p>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify">
                Though there is hope for a worldwide acceptance of the currency
                as UAE, UE, USA, Australia, and other developed nations are
                taking a keen interest in regulating the blockchain and building
                a safe environment for their citizen’s virtual trading, that
                hope is covered with fog at the moment.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="">
        <Container fluid>
          <Row>
            <Col>
              <AboutVideo videoUrl={IntroVideo} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-5">
        <Container>
          <Row>
            <AboutCard />
          </Row>
        </Container>
      </section>

      <section className="py-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center pt-5">
            <Col md={12} lg={6} className="p-3 p-md-5">
              <Image src={Aboutdecent} className="img-fluid" alt="" />
            </Col>
            <Col md={12} lg={6} className="ps-3 ps-md-5">
              <h2 className="font-outfit fw-700 text-white my-3 my-md-3">
                Fully decentralized. Completely secure.
              </h2>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify mb-4">
                Experience the Future of Decentralized Security with GKC Token -
                Your Gateway to Secure Transactions. Transforming the Way You
                Transact: GKC Token - The Ultimate Solution for Secure and
                Transparent Transactions in a Decentralized World.
              </p>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={6}
                  className="d-flex justify-content-start align-items-start mt-4"
                >
                  <Image src={Full1} className="img-fluid me-3" alt="" />
                  <h5 className="fw-400 fs-17 font-roboto text-logan">
                    Constant reviews and testing of our platform
                  </h5>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={6}
                  className="d-flex justify-content-start align-items-start mt-4"
                >
                  <Image src={Full2} className="img-fluid me-3" alt="" />
                  <h5 className="fw-400 fs-17 font-roboto text-logan">
                    Your funds are as secure as possible
                  </h5>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={6}
                  className="d-flex justify-content-start align-items-start mt-4"
                >
                  <Image src={Full3} className="img-fluid me-3" alt="" />
                  <h5 className="fw-400 fs-17 font-roboto text-logan">
                    Fully decentralized and non-custodial
                  </h5>
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={6}
                  className="d-flex justify-content-start align-items-start mt-4"
                >
                  <Image src={Full4} className="img-fluid me-3" alt="" />
                  <h5 className="fw-400 fs-17 font-roboto text-logan">
                    Built by seasoned crypto veterans{" "}
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="verticalroadmap-sec pt-5">
        <Container>
          <Row>
            <Col>
              {/* <RoadmapVertical /> */}
              <RoadMap2 />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-footer-upsec"></section>
      <Footer />
    </>
  );
};

export default About;
