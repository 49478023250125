import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const injected = new InjectedConnector({
    supportedChainIds: [137],
});

const POLLING_INTERVAL = 25000;
export const walletconnect = new WalletConnectConnector({
    rpc: { 137: 'https://polygon-rpc.com' },
    bridge: "https://bridge.walletconnect.org",
    pollingInterval: POLLING_INTERVAL,
});