import cogoToast from "cogo-toast";

export const MessageBox = (type, message) => {      // display message
  if (type == 'success') {
    cogoToast.success(message, { hideAfter: 3, position: 'top-right' });    // 3 sec
    return;
  }
  if (type == 'error') {
    cogoToast.error(message, { hideAfter: 3.5, position: 'top-right' });    // 3.5 sec
    return;
  }
  if (type == 'warn') {
    cogoToast.warn(message, { hideAfter: 3, position: 'top-right' });   // 3 sec
    return;
  }
  if (type == 'info') {
    cogoToast.info(message, { hideAfter: 3, position: 'top-right' });   // 3 sec
    return;
  }
}