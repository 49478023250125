import Web3 from 'web3';

// export const ROOT_URL = 'http://192.168.0.187:6969';
export const ROOT_URL = 'https://backend.gkctech.io';
// export const ROOT_URL = 'http://68.178.174.97:8001';
export const rpcUrl = 'https://polygon-bor-rpc.publicnode.com';
export const scanerUrl = 'https://polygonscan.com';

const url = new URL(window.location.href);
const baseUrl = url.port ? `${url.protocol}//${url.hostname}:${url.port}` : `${url.protocol}//${url.hostname}`;
export const REF_URL = `${baseUrl}/signup?ref=`;
export const DOMAIN_URL = "https://gkctech.io";

export const AIRDROP_CONTRACT = '0xc68a9993ce5a37772aa18b3c37e3656340a9bcbf';
export const TOKEN_CONTRACT = '0x0Ad3a144685F1E43371072418A9478cB5d926bd3';
export const ADMIN_ADDRESS = '0x000000000000000000000000000000000000dEaD';

export const getShortAddress = (address) => {
    return address.substr(0, 5) + '...' + address.substr(address.length - 5);
};

export const loadContract = (provider, abi, address) => {
    const w3 = new Web3(provider);
    return new w3.eth.Contract(abi, address);
};

export const convertPrice = (value, decimal) => {
    const price = (value / 10 ** decimal).toLocaleString("fullwide", { useGrouping: false });
    return price;
};

export const convertMultiPrice = (value, decimal) => {
    const price = (value * 10 ** decimal).toLocaleString("fullwide", { useGrouping: false });
    return price;
};

export const DECIMALS = 10 ** 18;