import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import UserAuthLayout from "../layout/UserAuthLayout";
import logo from "../../assets/images/GKC_Logo.png";
import { MessageBox } from "../../components/MessageBox";
import axios from "axios";
import {
  AIRDROP_CONTRACT,
  ROOT_URL,
  loadContract,
} from "../../components/constant";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../components/connectWallet/connectors";
import useAuth from "../../components/connectWallet/useAuth";
import airdropAbi from "../../abis/airdropAbi.json";
import AuthLayout from "../layout/AuthLayout";
import { AiFillHome } from "react-icons/ai";
import { TbArrowBackUp } from "react-icons/tb";
const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState({
    email: false,
    password: false,
  });
  const [loading, setLoading] = useState(false);
  const [adminAddress, setAdminAddress] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();
  const { active, account, library, chainId } = useWeb3React();

  const checkValidation = () => {
    if (loading) {
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      setValidation({ ...validation, email: true });
      return;
    }
    if (!password.length) {
      setValidation({ ...validation, password: true });
      return;
    }
    onSignIn();
  };

  const onSignIn = () => {
    setLoading(true);
    axios({
      method: "post",
      url: `${ROOT_URL}/Login`,
      data: {
        email: email,
        password: password,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.code === 200) {
          sessionStorage.setItem("email", res.data.data.email);
          sessionStorage.setItem("walletaddress", res.data.data.walletaddress);
          sessionStorage.setItem("refaddress", res.data.data.refaddess);
          sessionStorage.setItem("name", res.data.data.name);
          if ("isAdmin" in res.data.data) {
            sessionStorage.setItem("isAdmin", res.data.data.isAdmin);
            MessageBox("success", res.data.message);
            navigate("/admin-dashboard");
          } else {
            sessionStorage.setItem("txhash", res.data.data.transactionHash);
            MessageBox("success", res.data.message);
            navigate("/user-dashboard");
          }
          // if (res.data.data.walletaddress == adminAddress) {
          //   if (adminAddress !== account) {
          //     MessageBox(
          //       "error",
          //       "Wrong admin, Please change account and try again."
          //     );
          //     return;
          //   }
          //   MessageBox("success", "Admin LoggedIn Successfully");
          //   navigate("/admin-dashboard");
          //   return;
          // }

          // if (res.data.data.walletaddress != account) {
          //   sessionStorage.clear();
          //   MessageBox(
          //     "error",
          //     "Invalid wallet, Please change your wallet and try again."
          //   );
          //   navigate("/signin");
          //   return;
          // }

          // MessageBox("success", res.data.message);
          // navigate("/user-dashboard");
        } else {
          MessageBox("error", res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error.signin", err);
      });
  };

  const getAdminAddress = async () => {
    try {
      const airdropContract = loadContract(
        library.provider,
        airdropAbi,
        AIRDROP_CONTRACT
      );
      const admin = await airdropContract.methods.admin().call();
      setAdminAddress(admin);
    } catch (e) {
      console.log("Error.Contract-admin", e);
    }
  };

  useEffect(() => {
    if (account) {
      getAdminAddress();
    }
  }, [account]);

  return (
    <>
      <AuthLayout>
        <section className="auth-main py-5">
          <Container>
            <Row className="d-flex justify-content-center align-items-center text-center pb-5">
              <Col md={7} lg={5}>
                <div className="sign-card p-5 rounded mt-5">
                  <Link to="/">
                    <Image src={logo} alt="" className="img-fluid mb-4" />
                  </Link>

                  <h2 className="font-outfit fw-700 text-white text-capitalize mb-4">
                    Sign in
                  </h2>
                  <p className="font-roboto fw-400 text-logan fs-18 mb-3">
                    Enter your email address and password to sign in
                  </p>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="email"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setValidation({ ...validation, email: false });
                        }}
                      />
                      {validation.email && (
                        <label className="form-label m-0 text-danger">
                          {email
                            ? "Invalid email address"
                            : "Please enter email address"}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                          setValidation({ ...validation, password: false });
                        }}
                      />
                      {validation.password && (
                        <label className="form-label m-0 text-danger">
                          Please enter password
                        </label>
                      )}
                    </Form.Group>
                    <Button
                      variant="outline-success"
                      size="lg"
                      className="btn-primary-custom w-100 py-3 mb-3"
                      // onClick={() => {
                      //   if (account) {
                      //     checkValidation();
                      //   } else {
                      //     login(injected);
                      //   }
                      // }}
                      onClick={checkValidation}
                    >
                      {loading ? "Loading..." : " Sign In"}
                    </Button>
                  </Form>
                  <div>
                    <p className="font-roboto fw-400 text-logan fs-18">
                      Don’t have an account?{" "}
                      <Link
                        to="/signup"
                        className="font-roboto fw-400 text-logan fs-18 text-decoration-none"
                      >
                        Sign Up
                      </Link>
                    </p>
                    <Link
                      to="/forgot-password"
                      className="font-roboto fw-400 text-logan fs-18 text-decoration-none"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="mt-3">
                    <Link
                      to="/"
                      className="font-roboto fw-400 text-logan fs-18 text-decoration-none"
                    >
                      <TbArrowBackUp /> Back To Home <AiFillHome />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </AuthLayout>
    </>
  );
};

export default SignIn;
