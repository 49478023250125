import { rpcUrl, scanerUrl } from "../constant"

export const setupNetwork = async (externalProvider) => {

    const provider = externalProvider || window.ethereum
    const chainId = 137
    const name = 'Polygon'
    const nativeCurrencyName = 'MATIC'
    const nativeCurrencySymbol = 'MATIC'
    const blockExplorerUrl = scanerUrl

    if (provider) {
        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${chainId.toString(16)}` }],
            })
            return true
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: `0x${chainId.toString(16)}`,
                            chainName: name,
                            nativeCurrency: {
                                name: nativeCurrencyName,
                                symbol: nativeCurrencySymbol,
                                decimals: 18,
                            },
                            rpcUrls: [rpcUrl],
                            blockExplorerUrls: [blockExplorerUrl],
                        },],
                    })
                    return true
                } catch (error) {
                    console.error('Failed to setup the network in Metamask:', error)
                    return false
                }
            }
            return false
        }
    } else {
        console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
        return false
    }
}