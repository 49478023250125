import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import Whitepaperfile from "../assets/pdf/Whitepaper_GKC_Tech.pdf";
import { FaCloudDownloadAlt } from "react-icons/fa";
import Header from "../layout/Header.";
import Footer from "../layout/Footer";

const Whitepaper = () => {
  return (
    <>
      <Header />
      <div className="whitepaperdownload-btn text-end">
        <a
          className="btn-primary-custom text-decoration-none d-lg-inline-block mb-4 rounded-pill"
          href={Whitepaperfile}
          download
        >
          <FaCloudDownloadAlt size={25} />
        </a>
      </div>
      <section>
        <Container>
          <Row>
            <Col>
              <h1 className="display-3 fw-700 text-center gradient-color-one text-uppercase">
                Whitepaper <br />
                GKC - A CRYPTOCURRENCY THAT MAKES HEALTHCARE AFFORDABLE THROUGH
                OPTIMIZATION OF PRECLINICAL TRIALS AND MORE.
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <main className="px-0 px-md-5 py-5 whitepaper-maincont">
        <div className="p-3 p-md-5">
          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row>
                <Col xs={12}>
                  <h2 className="gradient-color-one fw-700 my-5">
                    1. EXECUTIVE SUMMARY
                  </h2>
                  <p>
                    GKC Tech, part of the Global Knowledge Center (GKC) aims to
                    make healthcare affordable for the masses by optimizing
                    everything from drug research to medical tourism, and the
                    whole lot in between. This shall be executed through the
                    ecosystem’s decentralized applications, AI initiatives in
                    Healthcare and the Global Knowledge Center (GKC) coin.
                  </p>
                  <p>
                    The GKC Tech is positioned for success as it dabbles in
                    several high-growth industries such as medical tourism, drug
                    research, AI in Healthcare development, and more. According
                    to our internal study, the two biggest roadblocks faced by
                    healthcare service providers are data security and seamless
                    international transactions — both shall be overcome through
                    GKC Tech.
                  </p>
                  <p>
                    We anticipate GKC Tech to be a high-growth ecosystem
                    because:
                  </p>
                  <ul>
                    <li>
                      The global wellness tourism industry is itself close to
                      becoming a trillion-dollar industry and back in 2017, was
                      worth over $639 billion dollars (The Indian Ministry of
                      Tourism, 2022).
                    </li>
                    <li>
                      According to McKinsey, it takes an average of 12 years to
                      complete the research and development process for a drug
                      (McKinsey, 2023). In 2019 itself, around $83 billion was
                      spent on drug research and when computed for 12 years, it
                      sums up to 996 billion even if the annual increase in
                      costs is not taken into account (Congressional Budget
                      Office, 2021). GKC aims to reduce that considerably by
                      optimizing preclinical development through seamless
                      patient data procurement.
                    </li>
                    <li>
                      The average cost of a healthcare data breach exceeds $9.42
                      million, and this increases year-on-year by 10% (The HIPAA
                      Journal, 2021). Healthcare service providers catering to a
                      global clientele are likely to incur higher compliance
                      costs and run bigger risks because while catering to a
                      global clientele of medical tourists and data sharers,
                      they must comply with multiple laws like the GDPR for EU
                      citizens, the Privacy Act for Australians, and so on. GKC
                      reduces these costs 2 and enables patients to control data
                      access and track their medical records.
                    </li>
                  </ul>
                  <p>
                    GKC is an ERC-20 token and its supply is limited to 100
                    million. It is the platform’s native cryptocurrency and a
                    utility token used globally across the medical industry. Its
                    use cases span medical tourism payments to patient data
                    procurement and incentivization. Regarding medical tourism,
                    the GKC will be used by GKC Tech’s Medical Tourism vertical
                    and as part of business development by independent medical
                    tourism service providers and their intermediaries.
                  </p>
                  <p>
                    Gradually, it shall also be used by drug research companies
                    to extend subsidized healthcare to those willing to assist
                    the research process by providing their medical data.
                  </p>
                  <p>
                    It, therefore, has the potential to act as a hedge against
                    healthcare inflation that is witnessed at various levels —
                    consulting fees, hospital bills, pharmaceutical drug costs,
                    and insurance premiums. It is worth taking note that since
                    2017, insurance premium costs have increased by a whopping
                    20% alongside dollar inflation (KFF, 2022).
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row>
                <Col>
                  <h2 className="gradient-color-one fw-700 my-5">
                    2. INTRODUCTION
                  </h2>
                  <p>
                    The GKC Tech aims to make healthcare more affordable by
                    reducing the gaps in the healthcare industry at various
                    levels. It lays the cornerstone for several healthcare
                    initiatives, from drug research to medical tourism.
                  </p>
                  <p>
                    Overall, GKC Tech aims to resolve concerns around surging
                    healthcare costs and inflation through its many use cases
                    and a cryptocurrency positioned to potentially increase in
                    value.
                  </p>
                  <p>
                    The journey begins with the launch of the GKC token — the
                    platform’s native cryptocurrency and the primary medium of
                    exchange for dApps that are part of the GKC Tech ecosystem.
                  </p>
                  <p>
                    The GKC token’s aim is to eventually become a global utility
                    token used across the medical industry. As far as the
                    medical tourism industry is concerned, it shall not only be
                    used by GKC Tech’s medical tourism vertical but also by
                    other medical tourism service providers and their
                    intermediaries.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className="gradient-color-one fw-600 my-5">
                    THE FIRST MILESTONE
                  </h3>
                  <p>
                    GKC Tech’s very first initiative would be to accelerate
                    medical tourism by empowering patients to make informed
                    choices with regard to their treatment. This involves
                    creating a secure and decentralized record-sharing protocol
                    that cannot be tampered with or stolen.
                  </p>
                  <p>
                    The mechanism not only protects patient data but also
                    reduces the data privacy compliance costs of healthcare
                    service providers. Furthermore, it allows patients worldwide
                    to securely share their medical records with doctors all
                    over the world and compare opinions.
                  </p>
                  <p>
                    They can also compare the lines of treatment and make an
                    informed decision without worrying about their privacy. This
                    is likely to result in higher patient satisfaction and
                    reduced record-keeping costs.
                  </p>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h3 className="gradient-color-one fw-600 my-5">
                    THE DISRUPTOR
                  </h3>
                  <p>
                    Until now, most medical tourists relied on referrals when
                    choosing healthcare service providers. GKC Tech’s
                    groundbreaking solutions are going to be a game-changer
                    because it empowers them to compare opinions and courses of
                    treatment, to make informed decisions. This is crucial for
                    medical tourists because they seek overseas treatment due to
                    the inefficiencies in their native healthcare systems. They
                    have every right to make an informed decision and GKC
                    facilitates just that.
                  </p>
                  <p>
                    Take for example a situation in which the concerned patient
                    needs an organ transplant within a week. In such
                    circumstances, it can be overwhelming to make
                    treatment-related decisions. Especially, in the digital
                    marketing and SEO era where promotions are the rage. These
                    hurdles are overcome through GKC as multiple opinions from
                    credible service providers can be sought in real-time.
                  </p>
                  <p>
                    Although it may seem hypothetical, these situations have
                    come up in Canada where in 2022, 39% of the 701 patients
                    waiting for an organ transplant died while still waiting
                    (Canadian Institute for Health Information, 2023).
                  </p>
                  <p>
                    At GKC Tech, we converge technology and healthcare to ensure
                    seamless decision making and lay the cornerstone to overcome
                    several roadblocks in the healthcare industry, and at
                    various levels — patients, healthcare service providers,
                    pharma supply chain, and drug research companies.
                  </p>
                  <p>
                    The scope for early supporters is enormous because the GKC
                    Tech platform dabbles in a trillion-dollar industry and
                    plans to gradually foray into education and finance as well.
                    In this whitepaper, we shall discuss this project’s
                    potential and get a glimpse of its growth prospects.
                  </p>
                </Col>
                <Col md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      GKC Tech’s dApps have several use cases such that span
                      medical tourism to secure patient data procurement.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row>
                <Col xs={12}>
                  <h2 className="gradient-color-one fw-700 my-5">
                    3. WHAT IS GKC TECH?
                  </h2>
                </Col>
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <p>
                    GKC Tech is a 360-degree solution for the healthcare
                    industry. The ecosystem consists of the GKC token,
                    Healthcare Ai solutions, and use- case-specific
                    decentralized applications. Collectively, these components
                    make the healthcare system more efficient and accessible.
                    Let us now get a quick glimpse into each of them.
                  </p>
                  <h3 className="gradient-color-one fw-600 my-5">GKC DAPPS</h3>
                  <p>
                    GKC Tech’s decentralized apps would be custom-built for
                    specific use cases such as enabling the secure exchange of
                    patient records for proactive decision-making. It would
                    enable patients from any corner of the world to securely
                    share their medical records and seek opinions from
                    specialists worldwide. According to the Indian Ministry of
                    Tourism, the global wellness tourism industry was worth $639
                    billion dollars in 2017 and has grown since then (The Indian
                    Ministry of Tourism, 2022).
                  </p>
                  <p>
                    For our internal study meant to align blockchain technology
                    with medical tourism, we dived deeper into this market.
                    While doing so, we identified two groups — those hailing
                    from developed nations and those from developing nations.
                    While patients from developed nations prioritized quality
                    and privacy, those from developing nations sought
                    specialists unavailable in their nation. GKC Tech’s dApps
                    would overcome hurdles for both these groups by enabling
                    them to make the right decisions.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      GKC Tech dabbles in the global wellness and drug research
                      industries, which is each worth over a
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h2 className="gradient-color-one fw-700 my-5">
                    THE GKC TOKEN – A HEDGE AGAINST HEALTHCARE
                  </h2>
                  <h3 className="gradient-color-one fw-600 my-5">INFLATION</h3>
                  <p>
                    The GKC cryptocurrency is an ERC-20 token and its supply is
                    limited to 100 million, with an initial supply of 10 million
                    only. This limited supply and potential adoption could
                    result in value enhancement. Therefore, when GKC is used to
                    avail healthcare services, it acts as a hedge against
                    healthcare inflation.
                  </p>
                  <p>
                    Currently, healthcare is expensive due to inflation at
                    various levels. From depleting fiat currency values to
                    increasing healthcare costs and everything in between. It is
                    a known fact that the US lacks a free public healthcare
                    system and therefore a great deal of reliance is placed on
                    insurance. However, since 2017 there has been a 20% increase
                    in the cost of the average family premium, and that
                    alongside dollar inflation worsens the situation (KFF,
                    2022).
                  </p>
                  <p>
                    In a nation where close to 13% of the population lives below
                    the poverty line and on Supplemental Nutrition Assistance
                    Program (SNAP) benefits, the average monthly health
                    insurance premium of $928 per person can be overwhelming
                    (Forbes, 2023). For those without insurance coverage,
                    healthcare costs in the US can particularly be a nightmare.
                    An organ transplant, which is a tedious procedure with long
                    wait times can cost anywhere from $400,000 to over a million
                    dollars.
                  </p>
                  <p>
                    A similar situation prevails worldwide, and these healthcare
                    costs only increase over time, while the value of the
                    respective fiat currencies depletes due to rising inflation.
                    This is precisely where GKC comes in and solves many
                    concerns. Imagine buying a bitcoin for $0.40 in 2010 and in
                    2023 availing services worth over $29,000 (Bankrate, 2023).
                    That’s the power GKC brings to the table because this
                    cryptocurrency comes with a limited supply and is positioned
                    to increase in value. This effectively counters the surging
                    healthcare costs and creates a profitable medium of exchange
                    for everyone involved. However, the GKC cryptocurrency is a
                    utility token and not an investment instrument or security.
                    The value enhancement is strictly estimated based on the
                    increasing use cases.
                  </p>
                  <p>
                    Now that we have understood the two major components of the
                    GKC ecosystem, let us dive deeper into comprehending the
                    need for such an ecosystem.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      GKC Tech is positioned to combat surging healthcare
                      inflation as a limited supply utility token.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      The US also finds it hard to deal with the surging health
                      insurance premiums that have gone up by 20% in 2017, with
                      an average monthly premium of $928 per person.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row className="d-flex justify-content-center align-items-start">
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h2 className="gradient-color-one fw-700 my-5">
                    4. NEED FOR GKC
                  </h2>
                  <p>
                    As we have already discussed, the GKC Tech platform is an
                    innovative blockchain-based solution architected to combat
                    the surging healthcare costs at various levels. The goal is
                    to create a profitable market for everyone involved —
                    patients, healthcare service providers, drug research
                    companies, pharma manufacturers, wholesalers, and retailers.
                    This feat shall be achieved by aligning a multitude of use
                    cases with decentralization using Web 3.0, starting from
                    medical tourism and patient data incentivization for drug
                    research.
                  </p>
                  <h3 className="gradient-color-one fw-600 my-5">
                    4.1. MEDICAL TOURISM
                  </h3>
                  <p>
                    According to a Global Market report, over 14 million people
                    travel annually for healthcare purposes. This staggering
                    number is estimated to increase by 20% and while that
                    happens, there is a sheer need for a reliable community of
                    healthcare service providers and a secure medium of patient
                    data exchange (Yahoo Finance, 2023). GKC Tech’s mission is
                    to be that ecosystem and therefore our first initiative
                    would be to create a reliable community of patients and
                    credible service providers who recognize and accept the GKC
                    token as a medium of exchange.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    4.1.1. Cuts Data Privacy Costs
                  </h5>
                  <p>
                    We have already discussed how expensive healthcare is and
                    the need for an ecosystem that provides access to a
                    multitude of healthcare professionals. In doing so, patients
                    often worry about secure exchange while the healthcare
                    professions lose sleep over secure patient data storage.
                  </p>
                  <p>
                    Data storage can be an expensive ordeal as these service
                    providers must comply with various data privacy laws. For
                    example, they must comply with the General Data Protection
                    Regulation (GDPR) for European patients, the Health
                    Insurance and Portability and Accountability Act (HIPAA) for
                    Americans, and other similar data security laws based on
                    where the patient comes from.
                  </p>
                  <p>
                    As the average cost of GDPR compliance is over $1 million,
                    it causes a great deal of financial burden on the service
                    provider (IT Governance, 2023). GKC makes this entire
                    process cheaper by lifting the burden off their shoulders
                    and at the same time, enabling patients to consult vetted
                    and certified healthcare professionals.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      Around 14 million medical tourists travel far and wide
                      annually in search of healthcare services and need
                      credible service providers and data sharing medium.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      The average HIPAA breach costs healthcare service
                      providers $9.42 million while the average GDPR compliance
                      costs around $1 million dollars. These costs increase
                      while catering to a global clientele.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h5 className="gradient-color-one fw-600 my-4">
                    4.1.2. Combat Long Wait Times
                  </h5>
                  <p>
                    GKC’s secure data exchange enables patients to consult and
                    choose from a reliable pool of medical professionals even on
                    short notice. This can be lifesaving for many who’ve been
                    waiting for years to undergo certain procedures. For
                    example, in 2022, 39% of the 701 patients in Canada died
                    waiting for an organ transplant (Canadian Institute for
                    Health Information, 2023).
                  </p>
                  <p>
                    A similar situation persists in the UK and the US. A kidney
                    transplant from a deceased donor in the UK has a wait time
                    of 2-3 years (NHS, 2023). In the US, a kidney transplant may
                    require a wait time of 3 to 5 years, and sometimes even
                    longer than that (National Kidney Foundation, 2023). This is
                    impractical given the fact that a person after a kidney
                    failure can only survive a few days or weeks without
                    treatment, and up to 30 years on dialysis (Cleveland Clinic,
                    2022).
                  </p>
                  <p>
                    So, although developed countries like Canada and the UK
                    provide free healthcare services, there are deficiencies and
                    exclusions due to which patients prefer to travel abroad for
                    their treatment. These patients have little time to do their
                    own research and need quick access to vetted and certified
                    professionals and that’s exactly what GKC brings to the
                    table.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      Canada lost 39% of its citizens waiting for an organ
                      transplant in 2022. In the UK and the US also, it takes
                      between 2-3 years to get an organ transplant.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h5 className="gradient-color-one fw-600 my-4">
                    4.1.3. Planned Hospitalizations Simplified
                  </h5>
                  <p>
                    It’s not always the organ transplant that takes time. Due to
                    the increasing population and a disproportionate number of
                    professionals and hospital beds, basic treatments can also
                    be overwhelming, and this has been a common concern in
                    Canada. According to a media reports, the average wait time
                    for treatments after a family doctor referral was 27.4
                    weeks, which can be a painstakingly long period for somebody
                    struggling with a painful health condition (National Post,
                    2022).
                  </p>
                  <p>
                    The US also has similar concerns, and the average wait
                    period is about 20.6 days (AAFP, 2022). These could be
                    planned joint replacement surgeries or other procedures that
                    are less urgent. In such cases also, GKC’s partners would
                    serve as credible service providers who accept a
                    cryptocurrency that beats inflation both at the fiat and
                    healthcare levels.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    4.1.4. Insurance Does Not Cover Everything
                  </h5>
                  <p>
                    Quite a few healthcare costs are not covered under the
                    insurance policy and can be extremely expensive. These may
                    include cosmetic surgeries, dental braces unless the caveats
                    are met, IVF treatments, etc... In such cases, holding the
                    GKC token can turn out to be extremely beneficial as it
                    grows in value and covers up for many treatments that the
                    insurance does not.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    4.1.5. Global Medium of Exchange
                  </h5>
                  <p>
                    GKC Tech aims to turn the GKC coin into a global medium of
                    exchange in the healthcare industry, and not limit it to
                    internal use. So, even though the platform is likely to
                    itself foray into the medical tourism industry, the token
                    shall also be used by other medical tourism service
                    providers and their intermediaries.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      Planned hospitalizations for conditions other than organ
                      transplant can also take an average of 20 to 27.4 days in
                      Canada and the US.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h2 className="gradient-color-one fw-700 my-5">
                    4.2 DRUG RESEARCH THROUGH PATIENT DATA
                  </h2>
                  <h3 className="gradient-color-one fw-600 my-5">
                    {" "}
                    INCENTIVIZATION
                  </h3>
                  <p>
                    According to McKinsey, preclinical development takes an
                    average of 12 years before a drug is ready for launch
                    (McKinsey, 2023). The process encompasses various phases but
                    the most crucial one is patient data procurement. This is a
                    tedious process because the patient data must come from all
                    over the world. The data is then segregated based on region,
                    ethnicity, age group, and other factors to ensure safety
                    because the reactions to a drug may vary based on those
                    parameters.
                  </p>
                  <p>
                    The fast-evolving data privacy and healthcare regulations
                    like the GDPR in the European Union, HIPAA in the United
                    States, Privacy Act 1988 in Australia and so on also
                    contribute to the delay because compliance is complex. This
                    creates a major roadblock in drug development. As this is a
                    global concern, there has to be a global solution and this
                    is where GKC steps in and simplifies patient data
                    procurement, which optimizes drug discovery and research.
                  </p>
                  <p>
                    Faster procurement ensures lesser preclinical development
                    time and reduces overall costs, enabling drug companies to
                    provide drugs at a lower cost. Besides this indirect
                    benefit, GKC Tech also attempts to allow patients to
                    monetize their medical records and get incentivized for it.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      Preclinical drug development takes an average of 12 years
                      and costs $89 billion annually only in the US.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row>
                <Col xs={12}>
                  <h2 className="gradient-color-one fw-700 my-5">
                    5. WHY USE BLOCKCHAIN?
                  </h2>
                </Col>
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <p>
                    The GKC ecosystem deals with healthcare data and therefore
                    was built using highly secure technologies that ensure
                    better control. Blockchain technology’s inherent traits make
                    it a perfect fit because the data is encrypted using
                    advanced cryptography and its movement is recorded on an
                    immutable ledger.
                  </p>
                  <p>
                    This ensures tamper-free data storage and verifiable
                    time-stamped transactions, which aligned with our mission to
                    empower individuals and businesses with a secure,
                    decentralized, and tamper-proof next-gen solution. Let us
                    now understand some of the core benefits of building GKC on
                    Blockchain.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    5.1. Facilitates Global Medium of Exchange
                  </h5>
                  <p>
                    The GKC community taps into a global pool of healthcare
                    professionals and patients. This naturally gives rise to
                    cross-border transactions and blockchain technology allows
                    the launching and operation of a common cryptocurrency. The
                    GKC token, which is the native coin of the GKC platform
                    ensures seamless cross-border transactions and makes price
                    comparisons a whole lot easier. Also, it prevents problems
                    related to exchange rate fluctuations and payment
                    traceability.
                  </p>
                  <p>
                    Let’s take the first use case of GKC, which is medical
                    tourism to understand how it fits in. In medical tourism,
                    part or full prepayment is the norm. This is largely due to
                    the time sensitivity involved. The treatments and procedures
                    are to be completed within a pre-determined timeframe and
                    the availability of the healthcare professional on those
                    dates is crucial. This brings forth several challenges and a
                    few to mention are fluctuating exchange rates, and the high
                    transaction fees for immediate international transfers.
                    These are overcome using the GKC token which can be
                    transferred in real-time and would have a very low
                    transaction fee.
                  </p>
                  <p>
                    Yet another concern while making payments is privacy.
                    According to the CDC, a vast majority of Americans, mostly
                    women aged between 40 to 54 prefer to travel abroad for
                    cosmetic procedures (CDC, 2023). These individuals along
                    with others seeking personal health treatments are usually
                    concerned about confidentiality and that is well taken care
                    of through blockchain-based payments.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    5.2. Verifiable Professionals
                  </h5>
                  <p>
                    Patients seeking medical treatment abroad must separate the
                    grain from the chaff because, in times of digital marketing,
                    promotional content is all over the internet. That makes it
                    tedious for patients to finalize a reliable service
                    provider, but GKC Tech simplifies that by partnering with
                    credible professionals with a proven track record.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      The Blockchain technology encrypts data transactions and
                      stores it on an immutable decentralized ledger, making
                      crypto an ideal medium of exchange for medical tourism and
                      drug research where a global community is involved, and
                      confidentiality is crucial.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h5 className="gradient-color-one fw-600 my-4">
                    5.3. Control and Compliance
                  </h5>
                  <p>
                    By using blockchain technology, we put the patient in the
                    driver’s seat because the access can be managed by the data
                    owner. While the patient enjoys better control, the research
                    institute and other healthcare professionals stand to
                    benefit in the form of lower compliance costs with regard to
                    data transmission and storage because the records are
                    secured by cryptography and decentralized. In the Healthcare
                    industry, breaches can be devastating. According to IBM
                    Security, the average cost of a healthcare data breach is
                    around $9.42 million along with a 10% year-on- year increase
                    (The HIPAA Journal, 2021).
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    5.4. Data Integrity
                  </h5>
                  <p>
                    The blockchain is made of blocks that are interlinked with
                    hashes in a way that every previous block is linked to the
                    next one. Once data is on the blockchain, it cannot be
                    altered or deleted, and the entire history of data changes
                    is available to those involved. Therefore, even if the
                    records are tampered with, in a worst-case scenario, they
                    won’t go unnoticed.
                  </p>
                  <p>
                    Thus, blockchains increase the trustworthiness of
                    information. That is essential for the success of drug
                    research and development because companies spend billions of
                    dollars on it. As a matter of fact, the pharmaceutical
                    industry spent around $83 billion in 2019 on drug research,
                    and bringing down these costs can have a significant impact
                    on the overall drug development costs (Congressional Budget
                    Office, 2021). On the other hand, data integrity helps in
                    reducing the chances of drug failures which can cost as much
                    as $1 to $2 billion
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    5.5. Audits
                  </h5>
                  <p>
                    Blockchain is a transparent and traceable alternative to
                    closed servers. The ability to easily track data changes
                    makes it more credible and thus simplifies the audit
                    procedure. Healthcare audits are strict, and it is crucial
                    for healthcare organizations to quickly identify
                    inconsistencies and demonstrate accuracy.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    5.6. Interoperability Between Layers Of The Healthcare
                    Industry
                  </h5>
                  <p>
                    GKC is an ecosystem that consists of various stakeholders
                    between whom the data is to be exchanged. The multiple
                    layers of the healthcare industry include drug researchers
                    and developers, pharmaceutical manufacturers and suppliers,
                    hospitals, and clinics, etc... The use of Blockchain
                    technology standardizes data and facilitates seamless
                    exchange between these different layers, which lays the
                    foundation for a robust and sustainable ecosystem.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    5.7. Eliminates Intermediaries And Incentivizes Patient Data
                  </h5>
                  <p>
                    Pharmaceutical companies pay billions to procure data from
                    various organizations for research purposes. This spending
                    can be reduced by directly procuring the data from patients
                    and incentivizing them. As the Blockchain facilitates secure
                    data sharing without any intermediaries managing it, the
                    risk surface is greatly reduced and so are the costs of
                    secure and compliant data storage for which the
                    pharmaceutical companies were charged extra. Moreover, the
                    incentives can be through GKC’s native cryptocurrency.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      The average HIPAA breach costs $9.42 million while the
                      average GDPR compliance costs $1 million. These costs can
                      be reduced by adopting GKC’s solutions for secure data
                      storage and sharing.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      Patient data can be incentivized through GKC as the
                      transaction fees would be much lower than international
                      fiat transfers.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row className="d-flex justify-content-center align-items-center">
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h2 className="gradient-color-one fw-700 my-5">
                    6. TOKENOMICS AND BEYOND
                  </h2>
                  <p>
                    GKC is a utility token with a capped supply. It is the sole
                    medium of exchange within the GKC ecosystem and shall be
                    utilized to facilitate transactions both within and outside
                    the of the GKC ecosystem. Internally, it shall be used
                    between the stakeholders — healthcare service providers,
                    patients, and drug research companies.
                  </p>
                  <p>
                    Externally, it can be used by other medical tourism,
                    pharmaceutical, insurance or healthcare companies, and their
                    intermediaries for the exchange of data, consultations,
                    incentives, rewards, and any other purposes that the
                    community deems to be fit.
                  </p>
                  <p>
                    <b className="gradient-color-one fw-600">Token Name : </b>{" "}
                    Global Knowledge Center{" "}
                  </p>
                  <p>
                    <b className="gradient-color-one fw-600">Token Symbol : </b>{" "}
                    GKC
                  </p>
                  <p>
                    <b className="gradient-color-one fw-600">
                      Contract Address :
                    </b>{" "}
                    0x0Ad3a144685F1E43371072418A9478cB5d926bd3
                  </p>
                  <p>
                    <b className="gradient-color-one fw-600">Total Supply : </b>
                    100 million
                  </p>
                  <p>
                    <b className="gradient-color-one fw-600">
                      Initial Supply :{" "}
                    </b>{" "}
                    10 million
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      The GKC token’s supply is limited to 100 million, which is
                      rare for a utility token.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row>
                <Col xs={12} md={9} className="pe-3 pe-md-5">
                  <h5 className="gradient-color-one fw-600 my-4">
                    Capped Supply
                  </h5>
                  <p>
                    Historically, cryptocurrencies with a capped supply and a
                    sustainable business model have fared better than their
                    elastic supply counterparts. Yet, the capped supply model is
                    usually used in fintech use cases such as peer-to-peer
                    exchange, fractional investment, and so on. A standing
                    example is Bitcoin, which has surged from under a dollar to
                    over $29,000 in slightly over a decade.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    Reliable Stakeholders
                  </h5>
                  <p>
                    GKC is backed by promoters and is on the verge of
                    collaborating with reliable healthcare service providers,
                    across the globe. Stakeholders shall include corporate
                    hospitals and verified healthcare professionals.
                  </p>
                  <h5 className="gradient-color-one fw-600 my-4">
                    Hedge Against Growing Healthcare Costs
                  </h5>
                  <p>
                    Unlike fiat currency, cryptocurrencies do not inflate in
                    value. So, they act as a hedge against inflation. However, a
                    lot more is expected from this cryptocurrency.
                  </p>
                </Col>
                <Col xs={12} md={3}>
                  <div className="blockqoutes">
                    {" "}
                    <ImQuotesLeft
                      className="text-AmethystSmoke text-start"
                      size={50}
                    />{" "}
                    <h4 className="quotetag">
                      GKC is in the process of building partnerships with renown
                      healthcare service providers and drug research companies.
                      It could soon turn out to be a hedge against healthcare
                      inflation.
                    </h4>
                    <div className="text-end">
                      <ImQuotesRight className="text-AmethystSmoke" size={50} />{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <h2 className="gradient-color-one fw-700 my-5">
                    7. CONCLUSION
                  </h2>
                  <p>
                    The GKC ecosystem is on a mission to save lives by making
                    healthcare more affordable and accessible through its many
                    initiatives. As an ecosystem, GKC penetrates three
                    trillion-dollar industries — drug research and development,
                    medical tourism, and healthcare AI development.
                  </p>
                  <p>
                    Our roadmap includes overcoming the two major roadblocks
                    that cause concern in this industry. Firstly, secure data
                    storage for healthcare service providers, and better data
                    control for the patients.
                  </p>
                  <p>
                    By connecting stakeholders such as patients, healthcare
                    service providers, and drug research companies, the GKC Tech
                    strives to be a gold standard in the global healthcare
                    industry.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="subcontintr mb-5 p-1 p-md-4 p-lg-5">
            <Container>
              <Row className="bottomsec">
                <Col xs={12}>
                  <h2 className="gradient-color-one fw-700 my-5">
                    8. BIBLIOGRAPHY
                  </h2>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    AAFP. (2022, October 3).
                  </h6>
                  <p>
                    {" "}
                    Appointment wait times increase across specialties, but not
                    in family medicine. Retrieved from AAFP:{" "}
                    <a
                      href="https://www.aafp.org/pubs/fpm/blogs/inpractice/entry/wait-times.html"
                      target="blank"
                    >
                      https://www.aafp.org/pubs/fpm/blogs/inpractice/entry/wait-times.html
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    Bankrate. (2023, June 14).
                  </h6>
                  <p>
                    Bitcoin’s price history: 2009 to 2023. Retrieved from
                    Bankrate:
                    <a
                      href="https://www.bankrate.com/investing/bitcoin-price-
                  history/#:~:text=The%20New%20Liberty%20Standard%20Exchange,pric
                  e%20of%20%240.00099%20per%20bitcoin."
                      target="blank"
                    >
                      https://www.bankrate.com/investing/bitcoin-price-
                      history/#:~:text=The%20New%20Liberty%20Standard%20Exchange,pric
                      e%20of%20%240.00099%20per%20bitcoin.
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    Canadian Institute for Health Information. (2023, June 15).
                  </h6>
                  <p>
                    {" "}
                    Summary statistics on organ transplants, wait-lists and
                    donors. Retrieved from Canadian Institute for Health
                    Information:{" "}
                    <a
                      href="https://www.cihi.ca/en/summary-
                  statistics-on-organ-transplants-wait-lists-and-donors"
                      target="blank"
                    >
                      https://www.cihi.ca/en/summary-
                      statistics-on-organ-transplants-wait-lists-and-donors
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    CDC. (2023, May 1).
                  </h6>
                  <p>
                    CDC Yellow Book 2024. Retrieved from CDC:
                    <a
                      href="https://wwwnc.cdc.gov/travel/yellowbook/2024/health-care-
                  abroad/medical-tourism"
                      target="blank"
                    >
                      https://wwwnc.cdc.gov/travel/yellowbook/2024/health-care-
                      abroad/medical-tourism
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    Cleveland Clinic. (2022, April 10). Kidney Failure.
                  </h6>
                  <p>
                    Retrieved from Cleveland Clinic:
                    <a
                      href="https://my.clevelandclinic.org/health/diseases/17689-kidney-
                  failure#:~:text=Without%20dialysis%20or%20a%20kidney,to%2030%20y
                  ears%20on%20dialysis"
                      target="blank"
                    >
                      https://my.clevelandclinic.org/health/diseases/17689-kidney-
                      failure#:~:text=Without%20dialysis%20or%20a%20kidney,to%2030%20y
                      ears%20on%20dialysis.
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    Congressional Budget Office. (2021, April).
                  </h6>
                  <p>
                    Research and Development in the Pharmaceutical Industry.
                    Retrieved from Congressional Budget Office:
                    <a
                      href="https://www.cbo.gov/publication/57126#:~:text=The%20expected%20c
                  ost%20to%20develop,to%20more%20than%20%242%20billion"
                      target="blank"
                    >
                      https://www.cbo.gov/publication/57126#:~:text=The%20expected%20c
                      ost%20to%20develop,to%20more%20than%20%242%20billion.
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    Forbes. (2023, May 8).
                  </h6>
                  <p>
                    How Much Does Health Insurance Cost In 2023? Retrieved from
                    <a
                      href="https://www.forbes.com/advisor/health-insurance/how-
                  much-does-health-insurance-cost/"
                      target="blank"
                    >
                      https://www.forbes.com/advisor/health-insurance/how-
                      much-does-health-insurance-cost/
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    IT Governance. (2023, May 10).
                  </h6>
                  <p>
                    How Much Does GDPR Compliance Cost in 2023? Retrieved from
                    IT Governance:
                    <a
                      href=" https://www.itgovernance.eu/blog/en/how-much-does-gdpr-compliance-cost-in-2020#:~:text=For%20many%20organisations%2C%20it's%20too,according%20to%20a%20PwC%20report"
                      target="blank"
                    >
                      {" "}
                      https://www.itgovernance.eu/blog/en/how-much-does-gdpr-compliance-cost-in-2020#:~:text=For%20many%20organisations%2C%20it's%20too,according%20to%20a%20PwC%20report.
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    KFF. (2022, October 27).
                  </h6>
                  <p>
                    {" "}
                    2022 Employer Health Benefits Survey. Retrieved from KFF:
                    <a
                      href="https://www.kff.org/report-section/ehbs-2022-section-1-cost-of-
                  health-
                  insurance/#:~:text=The%20average%20annual%20premiums%20in,2017
                  %20and%2043%25%20since%202012"
                      target="blank"
                    >
                      https://www.kff.org/report-section/ehbs-2022-section-1-cost-of-
                      health-
                      insurance/#:~:text=The%20average%20annual%20premiums%20in,2017
                      %20and%2043%25%20since%202012.
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    McKinsey. (2023, February 10).
                  </h6>
                  <p>
                    Fast to first-in-human: Getting new medicines to patients
                    more quickly. Retrieved from McKinsey:
                    <a
                      href="https://www.mckinsey.com/industries/life-sciences/our-insights/fast-to-
                  first-in-human-getting-new-medicines-to-patients-more-quickly"
                      target="blank"
                    >
                      https://www.mckinsey.com/industries/life-sciences/our-insights/fast-to-
                      first-in-human-getting-new-medicines-to-patients-more-quickly
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    National Kidney Foundation. (2023).
                  </h6>
                  <p>
                    Understanding the transplant waitlist. Retrieved from
                    National Kidney Foundation:
                    <a
                      href="https://www.kidney.org/content/understanding-transplant-
                  waitlist#:~:text=Once%20you%20are%20added%20to,geographical%20r
                  egions%20of%20the%20country"
                      target="blank"
                    >
                      https://www.kidney.org/content/understanding-transplant-
                      waitlist#:~:text=Once%20you%20are%20added%20to,geographical%20r
                      egions%20of%20the%20country.
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    National Post. (2022, December 8).
                  </h6>
                  <p>
                    Canadians have never waited this long for medical treatment,
                    study finds. Retrieved from National Post:
                    <a
                      href="https://nationalpost.com/news/canada/fraser-institute-wait-times-
                  medical"
                      target="blank"
                    >
                      https://nationalpost.com/news/canada/fraser-institute-wait-times-
                      medical
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    NHS. (2023). ORGAN TRANSPLANTATION.
                  </h6>
                  <p>
                    Retrieved from NHS:
                    <a
                      href="https://www.nhsbt.nhs.uk/organ-transplantation/kidney/receiving-a-
                  kidney/how-long-is-the-wait-for-a-kidney/"
                      target="blank"
                    >
                      https://www.nhsbt.nhs.uk/organ-transplantation/kidney/receiving-a-
                      kidney/how-long-is-the-wait-for-a-kidney/
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    The HIPAA Journal. (2021, July 29).
                  </h6>
                  <p>
                    The Average Cost of a Healthcare Data Breach is Now $9.42
                    Million. Retrieved from The HIPAA Journal:
                    <a
                      href="https://www.hipaajournal.com/average-cost-of-a-healthcare-data-
                  breach-9-42-million-2021/"
                      target="blank"
                    >
                      https://www.hipaajournal.com/average-cost-of-a-healthcare-data-
                      breach-9-42-million-2021/
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    The Indian Ministry of Tourism. (2022, January 27).
                  </h6>
                  <p>
                    National Strategy and Roadmap for Medical and Wellness
                    Tourism. Retrieved from{" "}
                    <a
                      href="https://tourism.gov.in/:
                  https://tourism.gov.in/sites/default/files/2022-
                  05/National%20Strategy%20and%20Roadmap%20for%20Medical%20an
                  d%20Wellness%20Tourism.pdf"
                      target="blank"
                    >
                      https://tourism.gov.in/:
                      https://tourism.gov.in/sites/default/files/2022-
                      05/National%20Strategy%20and%20Roadmap%20for%20Medical%20an
                      d%20Wellness%20Tourism.pdf
                    </a>
                  </p>
                  <h6 className="gradient-color-one fw-700 mt-5">
                    Yahoo Finance. (2023, March 16).
                  </h6>
                  <p>
                    Medical Tourism In A Changing World 2023 - A Global Market
                    Report: Cosmetic, Dental and Fertility Treatments Lead the
                    Way. Retrieved from Yahoo Finance:
                    <a
                      href="https://finance.yahoo.com/news/medical-tourism-changing-world-
                  2023-
                  140300909.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29
                  vZ2xlLmNvbS8&guce_referrer_sig=AQAAAJwz-
                  qGcVyDt5tgwRsx37GK5KWAUT3WM-
                  x3wc7wlRvn4rjYSBg4v4t0ZerS53cYiDXE3bxbuMv9uL0Z3e2lJerIz5im7"
                      target="blank"
                    >
                      https://finance.yahoo.com/news/medical-tourism-changing-world-
                      2023-
                      140300909.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29
                      vZ2xlLmNvbS8&guce_referrer_sig=AQAAAJwz-
                      qGcVyDt5tgwRsx37GK5KWAUT3WM-
                      x3wc7wlRvn4rjYSBg4v4t0ZerS53cYiDXE3bxbuMv9uL0Z3e2lJerIz5im7
                    </a>
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Whitepaper;
