import copy from "copy-to-clipboard";
import { useState } from "react";
import { MessageBox } from "./components/MessageBox";

const useCopy = () => {

    const [copied, setCopied] = useState(false)

    const copyText = (text) => {
        copy(text)
        setCopied(true)
        MessageBox('success','Copied!')
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }

    return {
        copyText,
        copied
    }
}

export default useCopy