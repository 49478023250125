import React, { ReactDOM } from "react";

const ProgressBarc = ({progressWidth,usdPrice}) => {
  const Progress = ({ done }) => {
    const [style, setStyle] = React.useState({});

    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`,
      };

      setStyle(newStyle);
    }, 200);

    return (
      <div className="progress">
        <div className="progress-done progress-one" data-width={usdPrice} style={style}>
          {done}%
        </div>
      </div>
    );
  };

  // Add Below classes dynamic for defferant background color

  // progress-one
  // progress-two
  // progress-three
  // progress-four

  return (
    <>
      <div className="customprogres">
        <Progress done={progressWidth} />
      </div>
    </>
  );
};

export default ProgressBarc;
