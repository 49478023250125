import React from "react";
import { Button, Card, Col, Image } from "react-bootstrap";
import Servi1 from "../../assets/images/helthcare.png";
import Service2 from "../../assets/images/education.png";
import ServThree from "../../assets/images/finance.png";
import { Link } from "react-router-dom";
const ServiceCard = () => {
  const Services = [
    {
      title: "Healthcare",
      Img: Servi1,
      subtitle:
        "GKC will provide decision making as blockchain allows several doctors from different location to view the same data in real time. Transforms patient medical records to a decentralized system that can't be tampered with speeds up medical credential.",
    },
    {
      title: "education",
      Img: Service2,
      subtitle:
        "GKC Blockchain will be extremely beneficial in the education sector. It allows educational institutions to secure the data of their students. They are able to take ownership of their credentials, awards, certificates, and academic identity.",
    },
    {
      title: "finance",
      Img: ServThree,
      subtitle:
        "GKC will provide services in financial sector and global capital markets to manage credit score, records of financial transactions, ledger for payments, banking and lending services, Crypto trading in exchanges, Crypto assets in digital wallet etc.",
    },
  ];
  return (
    <>
      {Services.map((item) => (
        <Col
          xs={12}
          md={12}
          lg={4}
          key={item.index}
          className="d-flex align-items-stretch"
        >
          <Card className="serv-cards p-0 mb-4 mb-md-4 mb-lg-0">
            <Card.Header className="p-0">
              <div className="serv-thumb-bg">
                <Image
                  src={item.Img}
                  className="img-fluid cardimg d-block mx-auto"
                />
              </div>
            </Card.Header>
            <Card.Body className="p-4">
              <Card.Title className="text-capitalize fw-700 fs-26 text-white">
                {item.title}
              </Card.Title>
              <Card.Text className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify mb-4">
                {item.subtitle}
              </Card.Text>
              <Link
                to="/service"
                variant="secondary"
                className="btn-primary-custom text-decoration-none d-inline-block"
              >
                Know More
              </Link>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default ServiceCard;
