import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { FiMail } from "react-icons/fi";
const Profile = (props) => {
  return (
    <>
      <div>
        <Row className="d-flex justify-content-start align-items-end mb-5">
          <Col>
            <Image src={props.ProfileImg} className="img-fluid" />
          </Col>
          <Col md={7} className="ps-4">
            <h2 className="fw-500 font-roboto fs-30 text-white">
              {props.ProfileName}
            </h2>
            <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
              <FiMail className="me-1" /> {props.ProfileEmail}
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Profile;
