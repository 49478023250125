import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from "@web3-react/injected-connector"
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect, WalletConnectConnector, } from '@web3-react/walletconnect-connector'
import { setupNetwork } from "./setupNetwork"
import { MessageBox } from "../MessageBox"


const useAuth = () => {
    const { activate, deactivate, setError } = useWeb3React()

    // connect wallet
    const login = async (connector) => {
        if (typeof connector !== 'function' && connector) {
            activate(connector, async (error) => {
                if (error instanceof UnsupportedChainIdError) {
                    if(connector instanceof WalletConnectConnector) {
                        MessageBox("error", error.message)
                    }
                    setError(error)
                    const provider = await connector.getProvider()
                    const hasSetup = await setupNetwork(provider)
                    if (hasSetup) {
                        activate(connector)
                        sessionStorage.setItem("connect", true)
                        if(connector instanceof WalletConnectConnector) {
                            sessionStorage.setItem("walletname", "walletconnect")
                        } else {
                            sessionStorage.setItem("walletname", "metamask")
                        }
                    }
                } else {
                    if (error instanceof NoEthereumProviderError) {
                        MessageBox('Provider Error')
                    } else if (error instanceof UserRejectedRequestErrorInjected || error instanceof UserRejectedRequestErrorWalletConnect) {
                        if (connector instanceof WalletConnectConnector) {
                            const walletConnector = connector
                            walletConnector.walletConnectProvider = null
                        }
                        MessageBox('Please authorize to access your account')
                    } else {
                        MessageBox(error.message)
                    }
                }

            })

        } else {
            MessageBox('Unable to find connector')
        }
    }

    // disconnect wallet
    const logout = async (connector) => {
        if (connector instanceof WalletConnectConnector) {
            await connector.close()
            window.location.reload()
        } else {
            deactivate()
            window.location.reload()
        }
    }
    return { login, logout };
}

export default useAuth;