import React, { useRef, useEffect } from "react";

const AboutVideo = (props) => {
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.play();
  }, []);
  return (
    <>
      <video ref={videoRef} autoPlay muted controls={false} width="100%" loop>
        <source src={props.videoUrl} type="video/mp4" />
      </video>
    </>
  );
};

export default AboutVideo;
