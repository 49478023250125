import React, { useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  Button,
  Image,
} from "react-bootstrap";
import { BsFillBellFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { RiSearch2Line } from "react-icons/ri";
import { CgMenuGridO } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { TiThMenu } from "react-icons/ti";
import Face from "../../assets/images/Face.png";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../components/connectWallet/connectors";
import useAuth from "../../components/connectWallet/useAuth";
import { getShortAddress } from "../../components/constant";
import WalletModal from "../../components/WalletModal";

const AdminDashboardHeader = ({
  toggle,
  handleToggle,
  sidebar,
  handleSidebar,
  usersidebar,
  handleUserSidebar,
}) => {
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const navigate = useNavigate();
  const { account, active } = useWeb3React();

  return (
    <>
      <header className="header-dashboard">
        <Navbar
          bg="dark"
          expand="lg"
          className="py-3 px-3 W-100 bg-transparent"
        >
          <Container>
            {/* <Button
              variant="outline-primary"
              onClick={handleToggle}
              className="me-3 menu-btn p-0 border-0 d-none d-md-none d-lg-block"
            >
              {toggle ? (
                <RiMenuUnfoldFill size={25} />
              ) : (
                <RiMenuFoldFill size={25} />
              )}
            </Button> */}
            <Button
              variant="outline-primary"
              onClick={handleSidebar}
              className="me-3 menu-btn p-0 border-0 d-block d-md-block d-lg-none"
            >
              {sidebar ? <TiThMenu size={25} /> : <TiThMenu size={25} />}
            </Button>
            <Navbar.Brand href="/">
              <h2 className="mb-1 fw-700 font-roboto text-white">
                Admin Dashboard
              </h2>
            </Navbar.Brand>
            <div className="w-100 d-flex justify-content-end">
              {!active && (
                <Button
                  variant="outline-success"
                  className="btn-primary-custom-two d-block d-lg-none"
                  onClick={() => setOpenWalletModal(true)}
                >
                  {account ? getShortAddress(account) : "Connect Wallet"}
                </Button>
              )}
            </div>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {/* <Nav.Link href="#home" className="rounded-0 text-white ms-3">
                  <CgMenuGridO />
                </Nav.Link> */}
                {/* <Nav.Link
                  href="#link"
                  className="rounded-pill text-white ms-3 btn btn-danger px-3"
                >
                  <BsFillBellFill />
                  <span className="ms-1">15</span>
                </Nav.Link> */}
                {!active && (
                  <Button
                    variant="outline-success"
                    className="btn-primary-custom ms-3"
                    onClick={() => setOpenWalletModal(true)}
                  >
                    {account ? getShortAddress(account) : "Connect Wallet"}
                  </Button>
                )}

                {/* NOT ADMIN */}
                {active &&
                sessionStorage.getItem("walletaddress") &&
                account.toLocaleLowerCase() !==
                  sessionStorage
                    .getItem("walletaddress")
                    .toLocaleLowerCase() ? (
                  <div className="d-flex align-items-center">
                    <div className="text-danger">
                      Wrong address please connect with{" "}
                      {getShortAddress(sessionStorage.getItem("walletaddress"))}
                    </div>
                    <Dropdown className="ms-3">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="bg-transparent border-0"
                      >
                        <Image src={Face} alt="" className="img-fluid" />
                        <span className="ms-2 font-outfit fw-400 fs-16 ">
                          {sessionStorage.getItem("name") || "Admin Name"}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            sessionStorage.clear();
                            navigate("/signin");
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <Dropdown className="ms-3">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="bg-transparent border-0"
                    >
                      <Image src={Face} alt="" className="img-fluid" />
                      <span className="ms-2 font-outfit fw-400 fs-16 ">
                        {sessionStorage.getItem("name") || "Admin Name"}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/admin-profile");
                        }}
                      >
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          sessionStorage.clear();
                          navigate("/");
                        }}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Nav>
            </Navbar.Collapse>
            <WalletModal open={openWalletModal} setOpen={setOpenWalletModal} />
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default AdminDashboardHeader;
