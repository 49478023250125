import React, { useEffect, useState } from "react";
import UserDashBoardLayout from "../layout/UserDashBoardLayout";
import { Row, Col, Container, Card, Form, Button } from "react-bootstrap";
import Profile from "../../components/Profile";
import ProfilePic from "../../assets/images/userimg.png";
import { FaEdit } from "react-icons/fa";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { ROOT_URL } from "../../components/constant";
import { MessageBox } from "../../components/MessageBox";

const UserProfile = () => {

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState({ name: false, email: false, mobile: false, pwd1: false, pwd2: false });
  const [loading, setLoading] = useState(false);
  const { account } = useWeb3React()

  const checkValidation = () => {
    if (!account) {
      MessageBox('error', 'Please Connect Your Wallet');
      return;
    }
    if (account.toLocaleLowerCase() !== sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      );
      return;
    }
    if (loading) {
      return;
    }
    if (!name) {
      setValidation({ ...validation, name: true });
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      setValidation({ ...validation, email: true });
      return;
    }
    if (!/^[0-9]{5,11}$/.test(mobile)) {
      setValidation({ ...validation, mobile: true });
      return;
    }
    if (!/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(password)) {
      setValidation({ ...validation, pwd1: true });
      return;
    }
    changeProfileDetails();
  };

  const changeProfileDetails = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${ROOT_URL}/Update_User_Profile`,
      data: {
        name: name,
        email: email,
        mobile: mobile,
        password: password,
        walletaddress: account ? account : '',
      }
    }).then(res => {
      setLoading(false);
      if (res.data.code == 200) {
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("name", name);
        setEditMode(false);
        MessageBox('success', res.data.message);
      } else {
        MessageBox('error', res.data.message);
      }
    }).catch(err => {
      setLoading(false);
      console.log('Error.edit-profile', err);
    });
  }

  const getUserData = () => {
    axios({
      method: 'post',
      url: `${ROOT_URL}/Get_User_Profil_Data`,
      data: {
        email: sessionStorage.getItem('email'),
      }
    }).then(res => {
      if (res.data.code == 200) {
        setName(res.data.data.name);
        setEmail(res.data.data.email);
        setMobile(res.data.data.mobile);
        setPassword(res.data.data.password);
      }
    }).catch(err => {
      setLoading(false);
      console.log('Error.get-user-data', err);
    });
  }

  useEffect(() => {
    getUserData();
  }, [])

  return (
    <>
      <UserDashBoardLayout>
        <section className="py-4 py-md-5">
          <Container>
            <Row>
              <Col>
                <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                  Profile
                </h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between align-items-center">
              <Col>
                <Card className="reffrealcoed p-4">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-start">
                      <Col md={5}>
                        <Profile
                          ProfileImg={ProfilePic}
                          ProfileName={sessionStorage.getItem('name')}
                          ProfileEmail={sessionStorage.getItem('email')}
                        />
                      </Col>
                      <Col
                        md={5}
                        className="d-flex justify-content-end align-items-start"
                      >
                        {!editMode ?
                          <Button variant="light" onClick={() => setEditMode(true)}>
                            <FaEdit />
                          </Button>
                          :
                          <div className="d-flex w-50">
                            <Button
                              variant="info"
                              className="w-100 rounded-pill text-white fw-700"
                              onClick={() => checkValidation(false)}
                            >
                              {!loading ? 'Save' : "Loa!ding..."}
                            </Button>
                            <Button
                              variant="info"
                              className="w-100 rounded-pill text-white fw-700 ms-2 btn-danger"
                              onClick={() => setEditMode(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        }
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Form.Group
                            as={Col}
                            md={3}
                            className="mb-3 mb-md-0"
                            controlId="formGridEmail"
                          >
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Full Name"
                              disabled={!editMode || loading}
                              value={name}
                              onChange={(event) => {
                                setName(event.target.value);
                                setValidation({ ...validation, name: false });
                              }}
                            />
                            {
                              validation.name &&
                              <label className="form-label m-0 text-danger">
                                Please enter full name
                              </label>
                            }
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md={3}
                            className="mb-3 mb-md-0"
                            controlId="formGridEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="example@gmail.com"
                              disabled={!editMode || loading}
                              value={email}
                              onChange={(event) => {
                                setEmail(event.target.value);
                                setValidation({ ...validation, email: false });
                              }}
                            />
                            {
                              validation.email &&
                              <label className="form-label m-0 text-danger">
                                {email ? 'Invalid email address' : 'Please enter email address'}
                              </label>
                            }
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md={3}
                            className="mb-3 mb-md-0"
                            controlId="formGridEmail"
                          >
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="0000000000"
                              disabled={!editMode || loading}
                              value={mobile}
                              onChange={(event) => {
                                if (/^[0-9]{0,11}$/.test(event.target.value)) {
                                  setMobile(event.target.value)
                                  setValidation({ ...validation, mobile: false })
                                }
                              }}
                            />
                            {
                              validation.mobile &&
                              <label className="form-label m-0 text-danger">
                                {mobile ? 'Invalid mobile number' : 'Please enter mobile number'}
                              </label>
                            }
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md={3}
                            className="mb-3"
                            controlId="formGridEmail"
                          >
                            <Form.Label>Change Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Enter Password"
                              disabled={!editMode || loading}
                              value={password}
                              onChange={(event) => {
                                setPassword(event.target.value)
                                setValidation({ ...validation, pwd1: false })
                              }}
                            />
                            {
                              validation.pwd1 &&
                              <label className="form-label m-0 text-danger">
                                {password ? 'Please enter strong password' : 'Please enter password'}
                              </label>
                            }
                          </Form.Group>
                        </Row>

                        <div className="mt-4">
                          <h6 className="text-white fw-500 fs-14 font-outfit">
                            Join and earn free airdrop:
                          </h6>
                          <ul className="text-logan fw-400 fs-11 font-roboto ps-3 mb-0">
                            <li>
                            Signup to avail of Airdrop 100 GKC Token Worth 5.3 USD
	                            </li>
                            <li>
                            Connect your Wallet with the Polygon network
	                            </li>
                            <li>
                            Claim your Daily Minted token from Dapp
                            </li>
                            
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </UserDashBoardLayout>
    </>
  );
};

export default UserProfile;
