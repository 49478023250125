import React, { useEffect, useState } from "react";
import AdminDashBoardLayout from "../layout/AdminDashBoardLayout";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Table,
  Form,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { BiCopy } from "react-icons/bi";
import { BsFacebook, BsTelegram, BsTwitter, BsWhatsapp } from "react-icons/bs";
import {
  ADMIN_ADDRESS,
  AIRDROP_CONTRACT,
  DOMAIN_URL,
  REF_URL,
  ROOT_URL,
  convertPrice,
  getShortAddress,
  loadContract,
  rpcUrl,
} from "../../components/constant";
import { useWeb3React } from "@web3-react/core";
import useCopy from "../../useCopy";
import axios from "axios";
import Web3 from "web3";
import airdropAbi from "../../abis/airdropAbi.json";
import { MessageBox } from "../../components/MessageBox";
import truncateEthAddress from "truncate-eth-address";
import { FacebookMessengerShareButton } from "react-share";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { scanerUrl } from "../../components/constant";

const DirectTransferUsers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState(null);
  const itemsPerPage = 8;
  const { account, active } = useWeb3React();
  const { copyText, copied } = useCopy();
  const [searchInput, setSearchInput] = useState("");
  const [tempData, setTempData] = useState(null);
  const [directTransferList, setDirectTransferList] = useState(null)

  useEffect(() => {
    if (directTransferList) {
      const lastIndex = currentPage * itemsPerPage;
      const pageCount = Math.ceil(directTransferList.length / itemsPerPage);
      setPageCount(pageCount);
      const paginatedData = directTransferList.slice(lastIndex - itemsPerPage, lastIndex);
      setPageData(paginatedData);
    }
  }, [directTransferList, currentPage]);

  useEffect(() => {
    (async () => {
      const res_direct = await axios.post(`${ROOT_URL}/Get_Direct_Transfer_List`);
      if (res_direct.data.code === 200) {
        setDirectTransferList(res_direct.data.data.data)
        setTempData(res_direct.data.data.data)
      } else {
        setDirectTransferList([])
        setTempData([])
      }
    })()
  }, []);

  useEffect(() => {
    if (searchInput && tempData) {
      const searchData = tempData.filter(function (event) {
        return (
          event.name.toLocaleLowerCase().includes(searchInput.toLowerCase()) ||
          event.walletaddress.toLocaleLowerCase().includes(searchInput.toLowerCase()) ||
          event.txhash.toLocaleLowerCase().includes(searchInput.toLowerCase()) ||
          event.amount?.toString()?.toLocaleLowerCase().includes(searchInput.toLowerCase())
        );
      });
      setCurrentPage(1);
      setDirectTransferList(searchData);
    } else {
      setCurrentPage(1);
      setDirectTransferList(tempData);
    }
  }, [searchInput, tempData])

  function exportToExcel(jsonData) {
    // if (!account) {
    //   MessageBox("error", "Please Connect Your Wallet");
    //   return;
    // }
    // if (
    //   account.toLocaleLowerCase() !==
    //   sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    // ) {
    //   MessageBox(
    //     "error",
    //     `Wrong address please connect with ${sessionStorage.getItem(
    //       "walletaddress"
    //     )}`
    //   );
    //   return;
    // }
    if (jsonData.length) {
      // Create a new workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(jsonData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate an Excel file and save it
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, "Direct_Transfer_Users.xlsx");
    } else {
      MessageBox("error", "There is no data available for export");
    }
  }

  return (
    <>
      <AdminDashBoardLayout>
        <section className="py-5">
          <Container>
            <Row className="d-flex justify-content-end align-items-center">
              <Form.Group
                as={Col}
                xs={12}
                md={6}
                className="mb-4 d-flex align-items-center"
              >
                <div className="w-100 me-2">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(event) => setSearchInput(event.target.value)}
                  />
                </div>
                <Button
                  className="rounded btn-light text-black fw-500 export_btn"
                  onClick={() => {
                    exportToExcel(tempData ? tempData : []);
                  }}
                >
                  Export
                </Button>
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12}>
                <Table responsive className="text-white mb-0 users-table">
                  <thead>
                    <tr>
                      <th scope="col" className="py-2 px-3">
                        Name
                      </th>
                      <th scope="col" className="py-2 px-3">
                        Address
                      </th>
                      <th scope="col" className="py-2 px-3">
                        Amount
                      </th>
                      <th scope="col" className="py-2 px-3">
                        Trans. Hash
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData ? (
                      pageData.length ? (
                        pageData.map((i, index) => (
                          <tr>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {i.name ? i.name : '-'}
                            </td>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {getShortAddress(i.walletaddress)} {<BiCopy style={{ cursor: "pointer" }} onClick={() => { copyText(i.walletaddress); }} />}
                            </td>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {(convertPrice(i.amount,18))}
                            </td>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              <a target="_blank" href={`${scanerUrl}/tx/${i.txhash}`}>{i.txhash ? getShortAddress(i.txhash) : '-'}</a>  {<BiCopy style={{ cursor: "pointer" }} onClick={() => { copyText(i.txhash); }} />}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-white text-center">
                          <td
                            colSpan={6}
                            className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3"
                          >
                            NO DATA FOUND
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr className="text-white text-center">
                        <td
                          colSpan={6}
                          className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3"
                        >
                          Loading...
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col xs={12} className="d-flex justify-content-end">
                {pageData && pageData.length ? (
                  <ReactPaginate
                    pageCount={pageCount}
                    onPageChange={(event) => setCurrentPage(event.selected + 1)}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    containerClassName={"pagination-container"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"activepage"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    className="ps-0 my-5"
                    forcePage={currentPage - 1}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
        </section>

      </AdminDashBoardLayout>
    </>
  );
};

export default DirectTransferUsers;
