import React from "react";
import { Button, Card, Col, Image } from "react-bootstrap";
import ab1 from "../../assets/images/about-one.png";
import ab2 from "../../assets/images/about-two.png";
import ab3 from "../../assets/images/about-three.png";
import ab4 from "../../assets/images/about-four.png";
import ab5 from "../../assets/images/about-five.png";
import ab6 from "../../assets/images/about-six.png";
const AboutCard = () => {
  const Services = [
    {
      title: "Fully Decentralized ",
      Img: ab1,
    },
    {
      title: "Leverage Up To 30x",
      Img: ab2,
    },
    {
      title: "Secure And Reliable",
      Img: ab6,
    },
    {
      title: "Transparent",
      Img: ab3,
    },
    {
      title: "Community-Driven",
      Img: ab4,
    },
    {
      title: "Value Rewards",
      Img: ab5,
    },
  ];
  return (
    <>
      {Services.map((item) => (
        <Col xs={12} md={6} lg={4} key={item.index} className="">
          <Card className="bg-transparent text-center about-sec-imgsec">
            <Card.Body>
              <Image src={item.Img} className="img-fluid " />
              <Card.Title className="text-capitalize fs-22 fw-700 font-outfit text-logan mt-5 mb-3">
                {item.title}
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default AboutCard;
