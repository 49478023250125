import React, { useEffect, useState } from "react";
import UserDashBoardLayout from "../layout/UserDashBoardLayout";
import {
  Row,
  Col,
  Container,
  Card,
  Image,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import Exchange from "../../components/gkctolen/Exchange";
import { MessageBox } from "../../components/MessageBox";
import { AIRDROP_CONTRACT, TOKEN_CONTRACT, convertMultiPrice, convertPrice, loadContract } from "../../components/constant";
import airdropAbi from '../../abis/airdropAbi.json'
import tokenAbi from '../../abis/tokenAbi.json'
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { TbArrowsUpDown } from "react-icons/tb";

const UserBuy = () => {

  const { account, active, library } = useWeb3React();
  const [balance, setBalance] = useState({ matic: 0, token: 0 });
  const [tokensPerMatic, setTokensPerMatic] = useState(0);
  const [contentLoader, setContentLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [matic, setMatic] = useState("");
  const [token, setToken] = useState("");

  const getDashBoardData = async () => {
    setContentLoader(true);
    const w3 = new Web3(library.provider);
    try {
      if (account.toLocaleLowerCase() == sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()) {
        const tokenContract = loadContract(
          library.provider,
          tokenAbi,
          TOKEN_CONTRACT
        );
        const airdropContract = loadContract(
          library.provider,
          airdropAbi,
          AIRDROP_CONTRACT
        );

        const tx_permatic = await airdropContract.methods.USDPerToken().call();
        setTokensPerMatic(parseFloat(convertPrice(tx_permatic, 18)));

        const maticBal = await w3.eth.getBalance(account);
        const txToken_balance = await tokenContract.methods
          .balanceOf(account)
          .call();
        setBalance({
          matic: convertPrice(maticBal, 18),
          token: convertPrice(txToken_balance, 18),
        });
      }
      setContentLoader(false);
    } catch (e) {
      console.log("Error.Contract-token", e);
      setContentLoader(false);
    } finally {
      setContentLoader(false);
    }
  };

  useEffect(() => {
    if (account) {
      getDashBoardData();
    }
  }, [account]);



  const onBuyToken = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet");
      return;
    }
    if (account.toLocaleLowerCase() !== sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()) {
      MessageBox(
        "error",
        `Wrong address please connect ${sessionStorage.getItem(
          "walletaddress"
        )}`
      );
      return;
    }
    if (loading) {
      return;
    }
    if (matic && token) {
      if (matic < balance.matic) {
        setLoading(true);
        try {
          const airdropContract = loadContract(
            library.provider,
            airdropAbi,
            AIRDROP_CONTRACT
          );
          const txAirdrop_buy = await airdropContract.methods
            .buyTokens()
            .send({ from: account, value: convertMultiPrice(matic, 18) });
          setLoading(false);
          getDashBoardData()
          MessageBox("success", "Token Buy Successfully");
        } catch (e) {
          setLoading(false);
          MessageBox("error", e.message);
          console.log("Error.buy-token-contract", e);
        }
      } else {
        MessageBox("error", "Insufficient Available Balance");
      }
    } else {
      MessageBox('error', 'Please enter amount')
    }
  };


  return (
    <>
      <UserDashBoardLayout>
        <section className="pb-5">
          <Container>
            <Row>
              <Col xs={12}>
                <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                  BUY token
                </h5>
                <Card className="reffrealcoed p-4 p-md-5">
                  <Card.Body className="p-0">
                    {!account && (
                      <Row className="d-flex justify-content-between align-items-center mb-3">
                        <Col md={7}>
                          <h6>Connect your wallet to buy token</h6>
                        </Col>
                        <Col></Col>
                      </Row>
                    )}
                    <p className="text-end text-uppercase fs-14 fw-400 font-roboto text-logan">
                      Bal:{" "}
                      {contentLoader ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        balance.token
                      )}{" "}
                      GKC
                    </p>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="0.0000"
                        aria-label="0.0000"
                        value={token}
                        onChange={(event) => {
                          const input = event.target.value;
                          const pattern = /^-?\d*\.?\d*$/;
                          if (input && pattern.test(input)) {
                            setToken(input);
                            setMatic(parseFloat(input) * tokensPerMatic);
                          } else {
                            setMatic("");
                            setToken("");
                          }
                        }}
                      />
                      <Button variant="outline-secondary" className="px-4">
                        GKC
                      </Button>
                    </InputGroup>
                    <p className="text-end text-uppercase fs-14 fw-400 font-roboto text-logan">
                      Bal:{" "}
                      {contentLoader ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        balance.matic
                      )}{" "}
                      MATIC
                    </p>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="0.0000"
                        aria-label="0.0000"
                        value={matic}
                        onChange={(event) => {
                          const input = event.target.value;
                          const pattern = /^-?\d*\.?\d*$/;
                          if (input && pattern.test(input)) {
                            setMatic(input);
                            setToken(parseFloat(input) / tokensPerMatic);
                          } else {
                            setMatic("");
                            setToken("");
                          }
                        }}
                      />
                      <Button variant="outline-secondary" className="px-4">
                        MATIC
                      </Button>
                    </InputGroup>
                    <div className="text-center mt-4 mb-3">
                      <TbArrowsUpDown size={20} />
                    </div>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => onBuyToken()}
                    >
                      {loading ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        "BUY"
                      )}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                {/* <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                  Claim Token
                </h5>
                <Card className="reffrealcoed p-4">
                  <Card.Body className="p-0">
                    <p className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan">
                      Total Claimable Tokens:{" "}
                      {contentLoader ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        tokensForMinting
                      )}{" "}
                      GKC
                    </p>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => onClaimToken()}
                    >
                      {loadingClaim ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        "CLAIM"
                      )}
                    </Button>
                  </Card.Body>
                </Card> */}
              </Col>
            </Row>
          </Container>
        </section>
      </UserDashBoardLayout>
    </>
  );
};

export default UserBuy;
