import React, { useEffect, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  Button,
  Image,
} from "react-bootstrap";
import { BsFillBellFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { RiSearch2Line } from "react-icons/ri";
import { CgMenuGridO } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { TiThMenu } from "react-icons/ti";
import Face from "../../assets/images/Face.png";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../components/connectWallet/useAuth";
import {
  injected,
  walletconnect,
} from "../../components/connectWallet/connectors";
import {
  ADMIN_ADDRESS,
  AIRDROP_CONTRACT,
  ROOT_URL,
  getShortAddress,
  loadContract,
  scanerUrl,
} from "../../components/constant";
import WalletModal from "../../components/WalletModal";
import Web3 from "web3";
import axios from "axios";
import airdropAbi from "../../abis/airdropAbi.json";
import { MessageBox } from "../../components/MessageBox";

const UserDashboardHeader = ({
  toggle,
  handleToggle,
  sidebar,
  handleSidebar,
  usersidebar,
  handleUserSidebar,
}) => {
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const navigate = useNavigate();
  const { account, active, library } = useWeb3React();
  const [status, setStatus] = useState("Loading");
  const [loading, setLoading] = useState(false);

  const getTxStatus = async () => {
    axios({
      method: "post",
      url: `${ROOT_URL}/Get_Transaction_Status`,
      data: {
        hash: sessionStorage.getItem("txhash"),
      },
    })
      .then((res) => {
        if (res.data.code === 200) {
          setStatus(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Error.tx-status", err);
      });
  };

  useEffect(() => {
    if (
      account &&
      account.toLocaleLowerCase() ==
        sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    )
      getTxStatus();
  }, [account]);

  const airdropAgain = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet");
      return;
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect ${sessionStorage.getItem(
          "walletaddress"
        )}`
      );
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const airdropContract = loadContract(
        library.provider,
        airdropAbi,
        AIRDROP_CONTRACT
      );
      const txAirdrop = airdropContract.methods
        .airdrop(
          account,
          sessionStorage.getItem("refaddress")
            ? sessionStorage.getItem("refaddress")
            : ADMIN_ADDRESS
        )
        .send({ from: account })
        .on("transactionHash", (transactionHash) => {
          sessionStorage.setItem("txhash", transactionHash);
          axios({
            method: "post",
            url: `${ROOT_URL}/Update_Tx_Hash`,
            data: {
              hash: transactionHash,
              walletaddress: sessionStorage.getItem("walletaddress"),
            },
          });
          setLoading(false);
          getTxStatus();
        })
        .on("recipt", (recipt) => {
          setLoading(false);
          getTxStatus();
        })
        .catch((e) => {
          setLoading(false);
          MessageBox("error", e.message);
          console.log("Error.Contract-airdrop-again", e);
        });
    } catch (e) {
      setLoading(false);
      MessageBox("error", e.message);
      console.log("Error.Contract-airdrop-again", e);
    }
  };

  return (
    <>
      <header className="header-dashboard">
        <Navbar
          bg="dark"
          expand="lg"
          className="py-3 px-3 W-100 bg-transparent"
        >
          <Container>
            {/* <Button
              variant="outline-primary"
              onClick={handleToggle}
              className="me-3 menu-btn p-0 border-0 d-none d-md-none d-lg-block"
            >
              {toggle ? (
                <RiMenuUnfoldFill size={25} />
              ) : (
                <RiMenuFoldFill size={25} />
              )}
            </Button> */}
            <Button
              variant="outline-primary"
              onClick={handleSidebar}
              className="me-3 menu-btn p-0 border-0 d-block d-md-block d-lg-none"
            >
              {sidebar ? <TiThMenu size={25} /> : <TiThMenu size={25} />}
            </Button>

            <div className="d-block d-md-flex align-md-items-center justify-md-content-between">
              <Navbar.Brand href="/" className="mb-0">
                <h2 className="mb-0 fw-700 font-roboto text-white ">
                  User Dashboard
                </h2>
              </Navbar.Brand>
              <div className="w-100 d-flex justify-content-end">
                {!active && (
                  <Button
                    variant="outline-success"
                    className="btn-primary-custom-two d-block d-lg-none"
                    onClick={() => setOpenWalletModal(true)}
                  >
                    {active ? getShortAddress(account) : "Connect Wallet"}
                  </Button>
                )}
              </div>

              {(account &&
                account.toLocaleLowerCase() ==
                  sessionStorage
                    .getItem("walletaddress")
                    ?.toLocaleLowerCase() &&
                status != "Loading") ||
              status == "Success" ? (
                <>
                  {status == "Pending" && (
                    <div className="note_msg text-PictonBlue">
                      Your transaction has been pending
                      <a
                        className="ms-2 font-roboto fs-12 btn btn-light rounded-pill text-black fw-400"
                        target="_blank"
                        href={`${scanerUrl}/tx/${sessionStorage.getItem(
                          "txhash"
                        )}`}
                      >
                        View Transaction
                      </a>
                    </div>
                  )}
                  {status == "Fail" && (
                    <div className="note_msg text-danger">
                      Your transaction has been failed
                      <a
                        className="ms-2 font-roboto fs-12 btn btn-light rounded-pill text-black fw-400"
                        target="_blank"
                        href={`${scanerUrl}/tx/${sessionStorage.getItem(
                          "txhash"
                        )}`}
                      >
                        View Transaction
                      </a>
                      <Button
                        variant="info"
                        onClick={() => {
                          airdropAgain();
                        }}
                        className="ms-2 font-roboto fs-12 btn rounded-pill text-white fw-400"
                      >
                        {loading ? "Loading..." : "Airdrop Again"}
                      </Button>
                    </div>
                  )}
                </>
              ) : null}

              {status == "PENDING" &&
                account &&
                account.toLocaleLowerCase() ==
                  sessionStorage
                    .getItem("walletaddress")
                    ?.toLocaleLowerCase() && (
                  <div className="note_msg">
                    View your transaction at
                    <a
                      className="px-2 text-tx"
                      target="_blank"
                      href={`${scanerUrl}/tx/${sessionStorage.getItem(
                        "txhash"
                      )}`}
                    >
                      {getShortAddress(sessionStorage.getItem("txhash"))}
                    </a>
                  </div>
                )}
            </div>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {/* <Nav.Link href="#home" className="rounded-0 text-white ms-3">
                  <CgMenuGridO />
                </Nav.Link> */}
                {/* <Nav.Link
                  href="#link"
                  className="rounded-pill text-white ms-3 btn btn-danger px-3"
                >
                  <BsFillBellFill />
                  <span className="ms-1">15</span>
                </Nav.Link> */}

                {!active && (
                  <Button
                    variant="outline-success"
                    className="btn-primary-custom ms-3"
                    onClick={() => setOpenWalletModal(true)}
                  >
                    {active ? getShortAddress(account) : "Connect Wallet"}
                  </Button>
                )}
                {/* NOT USER */}
                {active &&
                sessionStorage.getItem("walletaddress") &&
                account.toLocaleLowerCase() !==
                  sessionStorage
                    .getItem("walletaddress")
                    .toLocaleLowerCase() ? (
                  <div className="d-flex align-items-center">
                    <div className="text-danger">
                      Wrong address please connect with{" "}
                      {getShortAddress(sessionStorage.getItem("walletaddress"))}
                    </div>
                    <Dropdown className="ms-3">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="bg-transparent border-0"
                      >
                        <Image src={Face} alt="" className="img-fluid" />
                        <span className="ms-2 font-outfit fw-400 fs-16 ">
                          {sessionStorage.getItem("name") || "User Name"}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            sessionStorage.clear();
                            navigate("/signin");
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <Dropdown className="ms-3">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="bg-transparent border-0"
                    >
                      <Image src={Face} alt="" className="img-fluid" />
                      <span className="ms-2 font-outfit fw-400 fs-16 ">
                        {sessionStorage.getItem("name") || "User Name"}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          sessionStorage.clear();
                          navigate("/");
                        }}
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Nav>
            </Navbar.Collapse>
            <WalletModal open={openWalletModal} setOpen={setOpenWalletModal} />
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default UserDashboardHeader;
