import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserAuthLayout from "../layout/UserAuthLayout";
import logo from "../../assets/images/GKC_Logo.png";
import ReactCodeInput from "react-verification-code-input";
import { ROOT_URL } from "../../components/constant";
import axios from "axios";
import { MessageBox } from "../../components/MessageBox";
import AuthLayout from "../layout/AuthLayout";


const SentOtp = () => {

  const [verifyCode, setVerifyCode] = useState('');
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate('/forgot-password')
    }
  }, [])

  const onVerify = () => {    // verify email verification code
    if (loading) {    // already process running
      return;
    }
    if (verifyCode.length < 6) {
      setValidation(true);
      return;
    }
    setLoading(true);
    axios({
      method: "post",
      url: `${ROOT_URL}/Verify_Otp`,
      data: {
        user_data: location.state ? location.state.user_data : '',
        otp: verifyCode,
      }
    }).then((res) => {
      setLoading(false);
      if (res.data.code == 200) {
        MessageBox("success", res.data.message);
        navigate("/reset-password", { state: { user_data: location.state.user_data } });
      } else {
        MessageBox("error", res.data.message);
      }
    }).catch((err) => {
      setLoading(false);
      console.log("Error.Verify-otp", err);
    });
  };

  return (
    <>
      <AuthLayout>

        <section className="auth-main py-5">
          <Container>
            <Row className="d-flex justify-content-center align-items-center text-center pb-5">
              <Col md={7} lg={5}>
                <div className="sign-card p-5 rounded mt-5">
                  <Image src={logo} alt="" className="img-fluid mb-4" />
                  <h2 className="font-outfit fw-700 text-white text-capitalize mb-4">
                    forgot password
                  </h2>
                  <p className="font-roboto fw-400 text-logan fs-18 mb-3">
                    Enter your OTP
                  </p>
                  <Form>
                    <Form.Group className="mb-3">
                      <ReactCodeInput
                        className="d-flex w-100"
                        type="number"
                        placeholder={'000000'}
                        fields={6}
                        autoFocus
                        onChange={(event) => { setVerifyCode(event); setValidation(false); }}
                      />
                    </Form.Group>
                    {
                      validation &&
                      <div className="w-100">
                        <label className="form-label m-0 mb-2 text-danger">
                          {verifyCode ? 'Invalid verification code' : 'Please enter verification code'}
                        </label>
                      </div>
                    }
                    <Button
                      variant="outline-success"
                      size="lg"
                      className="btn-primary-custom w-100 py-3"
                      onClick={() => onVerify()}
                    >
                      {loading ? "Loading..." : "Verify"}
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </AuthLayout>
    </>
  );
};

export default SentOtp;
