import React from "react";
import { Card, Col, Image } from "react-bootstrap";
import Poly from "../../assets/images/polygon.png";

const DataCard = (props) => {
  return (
    <>
      <Card className="data-cards p-0">
        <Card.Body className="p-4 text-center">
          <Card.Title className="text-capitalize font-roboto fw-400 fs-17 text-white">
            <Image src={props.Img} className="img-fluid me-3" />
            {props.title}
          </Card.Title>
          <Card.Text className="font-roboto text-AmethystSmoke fs-13 fw-400 mt-3">
            {props.subtitle}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default DataCard;
