import React, { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import UserDashboardHeader from "./UserDashboardHeader";
import UserDashboardSidebar from "./UserDashboardSidebar";

const UserDashBoardLayout = (props) => {
  const { children } = props;
  // THis is for mobile sidebar
  const [sidebar, setSidebar] = useState(false);
  const handleSidebar = () => setSidebar(true);
  const handleSidebarClose = () => setSidebar(false);

  const [usersidebar, setUserSidebar] = useState(false);
  const handleUserSidebar = () => setUserSidebar(true);
  const handleUserSidebarClose = () => setUserSidebar(false);

  // This is dashboard side bar for desktop
  const [isActive, setActive] = useState("false");
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
    setActive(!isActive);
  };
  return (
    <>
      <div className="header-divider"></div>

      <main
        className={
          isActive ? "myreal-container active " : "myreal-container-close"
        }
      >
        <aside className="sidebar d-none d-md-none d-lg-flex">
          <UserDashboardSidebar />
        </aside>

        <section className="main-content bg-White-Lilac">
          {/* <div className="header-divider"></div> */}
          <UserDashboardHeader
            toggle={toggle}
            handleToggle={handleToggle}
            sidebar={sidebar}
            handleSidebar={handleSidebar}
            usersidebar={usersidebar}
            handleUserSidebar={handleUserSidebar}
          />
          {/* <TopBar /> */}

          <section className="px-3">{children}</section>
          {/* <BottomBar /> */}
        </section>
      </main>
      {/* main Sidebar For mobile */}
      <Offcanvas
        show={sidebar}
        onHide={handleSidebarClose}
        placement="start"
        name="start"
        scroll={false}
        backdrop={false}
        className="dash-sidebar-mobile"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <UserDashboardSidebar />
        </Offcanvas.Body>
      </Offcanvas>
      {/* User SideBar for mobie */}
      <Offcanvas
        show={usersidebar}
        onHide={handleUserSidebarClose}
        placement="end"
        name="end"
        scroll={false}
        backdrop={false}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>
            <FaUserAlt size={18} className="me-2 align-baseline" /> User Name
          </Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>{/* <UseerDetailCard /> */}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UserDashBoardLayout;
