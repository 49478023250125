import React from "react";

const RoadMap2 = () => {
  return (
    <>
      <section className="roadmap-two">
        <div className="roadttimeline">
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                January 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Research started by the team On decentralised infrastructure.
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-right">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                April 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Conceptualisation of the project.
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                July 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Team will be execute platform and services.
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-right">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                September 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Community development and Airdrop will be completed.
              </p>
            </div>
          </div>

          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                December 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Listing of GKC tokens worldwide in many exchanges.
              </p>
            </div>
          </div>

          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-orange font-outfit  mb-3">
              We Successfully Completed Our 2023 Roadmap's Milestones
              </h5>              
            </div>
          </div>

         
         
          <div className="roadmaincont roadm-right">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
              January 2024
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
              Initiation of research for the development of GKC Klinica
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
              April 2024
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
              Successful launch of the GKC Klinica platform nationwide in India
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-right">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
              May 2024
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
              Official launch of GKC Klinica
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
              June 2024
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
              Launch of the GKC Klinica mobile application on Android and iOS platforms
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-right">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
              July 2024
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
              Commencement of the Blockchain launch
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
              August 2024
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
              Expansion of GKC Token listing on multiple exchanges
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-right">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
              September 2024
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
              Expansion of GKC Klinica services to the United Kingdom and the United States of America
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
              December 2024
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
              Progression through the development stages of Blockchain technology
              </p>
            </div>
          </div>


          
        </div>
        
      </section>
    </>
  );
};

export default RoadMap2;
