import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup';
import useAuth from './connectWallet/useAuth';
import { injected, walletconnect } from './connectWallet/connectors';

function WalletModal({ open, setOpen }) {

    const {login} = useAuth()

    const onClickMetamask = async () => {
        try {
            await login(injected)
            sessionStorage.setItem("connect", true)
            sessionStorage.setItem("walletname", "metamask")
            setOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    const onClickWalletconnect = async () => {
        try {
            await login(walletconnect)
            sessionStorage.setItem("connect", true)
            sessionStorage.setItem("walletname", "walletconnect")
            setOpen(false)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" className='text-black' centered show={open} onHide={() => setOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Choose Wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    <ListGroup.Item style={{cursor: 'pointer'}} onClick={() => {onClickMetamask()}}>Metamask</ListGroup.Item>
                    <ListGroup.Item style={{cursor: 'pointer'}} onClick={() => {onClickWalletconnect()}}>Wallet Connect</ListGroup.Item>
                </ListGroup>
            </Modal.Body>
        </Modal>
    )
}

export default WalletModal