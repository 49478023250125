import {
  Container,
  Row,
  Col,
  Card,
  Image,
  InputGroup,
  Button,
  Form,
  Table,
} from "react-bootstrap"
import { useWeb3React } from "@web3-react/core"
import React, { useEffect, useState } from "react"
import {
  AIRDROP_CONTRACT,
  DOMAIN_URL,
  REF_URL,
  ROOT_URL,
  TOKEN_CONTRACT,
  convertMultiPrice,
  convertPrice,
  getShortAddress,
  loadContract,
  rpcUrl,
  scanerUrl,
} from "../../components/constant"
import airdropAbi from "../../abis/airdropAbi.json"
import tokenAbi from "../../abis/tokenAbi.json"
import { MessageBox } from "../../components/MessageBox"
import axios from "axios"
import AdminDashBoardLayout from "../layout/AdminDashBoardLayout"
import DataCard from "../../userdashboard/components/DataCard"
import Airdrop from "../../assets/images/airdrop.png"
import Web3 from "web3"
import { TbArrowsUpDown } from "react-icons/tb"
import truncateEthAddress from "truncate-eth-address"
import { BiCopy } from "react-icons/bi"
import { BsFacebook, BsTelegram, BsTwitter, BsWhatsapp } from "react-icons/bs"
import useCopy from "../../useCopy"
import { FacebookMessengerShareButton } from "react-share"
import { async } from "q"

const AdminDashboard = () => {
  const { account, active, library } = useWeb3React()
  const [transferAmountContract, setTransferAmountContract] = useState("")
  const [adminAddress, setAdminAddress] = useState("")
  const [newAdmin, setNewAdmin] = useState("")
  const [directTransferAddress, setDirectTransferAddress] = useState("")
  const [directTransferAmount, setDirectTrasferAmount] = useState("")
  const [currentGkcPrice, setCurrentGkcPrice] = useState(0)
  const [newPrice, setNewPrice] = useState("")
  const [status, setStatus] = useState(false)
  const [airdropAmt, setAirdropAmt] = useState(0)
  const [percentLevel1, setPercentlevel1] = useState(0)
  const [percentLevel2, setPercentlevel2] = useState(0)
  const [contentLoader, setContentLoader] = useState(false)
  const [tokensPerMatic, setTokensPerMatic] = useState(0)
  const [newAirdropAmount, setNewAirdropAmount] = useState(0)
  const [updatePercent1, setUpdatePercent1] = useState(0)
  const [updatePercent2, setUpdatePercent2] = useState(0)
  const [tokenContractAddress, setTokenContractAddress] = useState("")
  const [totalRefLvl1, setTotalRefLvl1] = useState(0)
  const [totalRefLvl2, setTotalRefLvl2] = useState(0)
  const [contractBal, setContractBal] = useState(0)
  const [totalTokensPurchase, setTotalTokensPurchase] = useState(0)
  const [totalTokensAirdroped, setTotalTokensAirdroped] = useState(0)
  const [totalTokensAllowted, setTotalTokensAlloetwd] = useState(0)
  const [balance, setBalance] = useState({ matic: 0, token: 0 })
  const [loading, setLoading] = useState(false)
  const [matic, setMatic] = useState("")
  const [token, setToken] = useState("")
  const { copyText, copied } = useCopy()
  const [totalMinted, setTotalMinted] = useState(0)
  const [totalDirectTransfer, setTotalDirectTrasnfer] = useState(0)
  const [name, setName] = useState('')
  const [airdropAddress, setAirdropAddress] = useState('')
  const [refAddress, setRefAddress] = useState('')

  useEffect(() => {
    (async () => {
      const res_admin_data = await axios.post(`${ROOT_URL}/Get_Admin_Data`)
      if (res_admin_data.data.code === 200) {
        const users = res_admin_data.data.data
        let total_minted = 0
        const httpProvider = new Web3(new Web3.providers.HttpProvider(rpcUrl))
        const airdropContract = loadContract(
          httpProvider,
          airdropAbi,
          AIRDROP_CONTRACT
        )
        await Promise.all(
          users.map(async (i) => {
            const tx_availableforminting = await airdropContract.methods
              .getUserTokensAvailableForMinting(i.walletaddress)
              .call()
            total_minted += parseFloat(convertPrice(tx_availableforminting, 18))
          })
        )
        setTotalMinted(total_minted.toFixed(4))
      }
    })()
  }, [account])

  // useEffect(() => {
  //   (async () => {
  //     const res_direct = await axios.post(`${ROOT_URL}/Get_Direct_Transfer_List`);
  //     if (res_direct.data.code === 200) {
  //       setTotalDirectTrasnfer(parseFloat(res_direct.data.data.totalAmount).toFixed(4))
  //     }
  //   })()
  // }, []);

  const getAdminData = async () => {
    setContentLoader(true)
    try {
      const httpProvider = new Web3(new Web3.providers.HttpProvider(rpcUrl))
      const airdropContract = loadContract(
        httpProvider,
        airdropAbi,
        AIRDROP_CONTRACT
      )
      const tokenContract = loadContract(
        httpProvider,
        tokenAbi,
        TOKEN_CONTRACT
      )

      const balance = await tokenContract.methods
        .balanceOf(AIRDROP_CONTRACT)
        .call()
      setContractBal(convertPrice(balance, 18))

      const tokenContractAddress = await airdropContract.methods.token().call()
      setTokenContractAddress(tokenContractAddress)

      const admin = await airdropContract.methods.admin().call()
      setAdminAddress(admin)

      const totaltokenpurchase = await airdropContract.methods
        .totalTokensPurchasedByBuy()
        .call()
      setTotalTokensPurchase(convertPrice(totaltokenpurchase, 18))

      const amount = await airdropContract.methods.airdropAmount().call()
      setAirdropAmt(convertPrice(amount, 18))

      const level1 = await airdropContract.methods
        .referralLevelOneBonusPercentage()
        .call()
      setPercentlevel1(level1)

      const level2 = await airdropContract.methods
        .referralLevelTwoBonusPercentage()
        .call()
      setPercentlevel2(level2)

      const totalreflevel1 = await airdropContract.methods
        .totalReferalLevelOneReward()
        .call()
      setTotalRefLvl1(convertPrice(totalreflevel1, 18))

      const totalreflevel2 = await airdropContract.methods
        .totalReferalLevelTwoReward()
        .call()
      setTotalRefLvl2(convertPrice(totalreflevel2, 18))

      const tx_permatic = await airdropContract.methods.USDPerToken().call()
      setTokensPerMatic(parseFloat(convertPrice(tx_permatic, 18)))

      const tx_status = await airdropContract.methods.paused().call()
      setStatus(tx_status)

      const tx_airdroped = await airdropContract.methods
        .totalTokensAirdropped()
        .call()
      setTotalTokensAirdroped(convertPrice(tx_airdroped, 18))

      const tx_allowted = await airdropContract.methods
        .totalTokensAllotedTillDate()
        .call()
      setTotalTokensAlloetwd(parseFloat(convertPrice(tx_allowted, 18)))
      const tx_direct_transfer = await airdropContract.methods
        .totalDirectTransfer()
        .call()
      setTotalDirectTrasnfer(convertPrice(tx_direct_transfer, 18))
      setContentLoader(false)
    } catch (error) {
      console.log(error)
    } finally {
      setContentLoader(false)
    }
  }


  useEffect(() => {
    getAdminData()
  }, [])

  const transferTokensInContract = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    try {
      const tokenContract = loadContract(
        library.provider,
        tokenAbi,
        TOKEN_CONTRACT
      )
      const transfer = await tokenContract.methods
        .transfer(
          AIRDROP_CONTRACT,
          convertMultiPrice(transferAmountContract, 18)
        )
        .send({ from: account })
      getAdminData()
      MessageBox("success", "Token Transfered Successfully")
    } catch (error) {
      MessageBox("error", "Transaction Fail")
      console.log(error)
    }
  }

  const adminWithdrawal = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    try {
      const airdropContract = loadContract(
        library.provider,
        airdropAbi,
        AIRDROP_CONTRACT
      )
      const withdraw = await airdropContract.methods
        .adminTokenWithdrawal()
        .send({ from: account })
      getAdminData()
      MessageBox("success", "Token Withdraw Successfully")
    } catch (error) {
      MessageBox("error", error.message)
      console.log(error)
    }
  }

  const changeAdmin = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    if (newAdmin) {
      try {
        const airdropContract = loadContract(
          library.provider,
          airdropAbi,
          AIRDROP_CONTRACT
        )
        const change = await airdropContract.methods
          .changeAdmin(newAdmin)
          .send({ from: account })
        getAdminData()
        await axios({
          method: "post",
          url: `${ROOT_URL}/Change_Admin`,
          data: {
            old_address: account,
            new_address: newAdmin,
          },
        })
        sessionStorage.setItem("walletaddress", newAdmin)
        MessageBox("success", "Admin Changed Successfully")
      } catch (error) {
        MessageBox("error", error.message)
        console.log(error)
      }
    }
  }

  const directTransfer = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    try {
      const airdropContract = loadContract(
        library.provider,
        airdropAbi,
        AIRDROP_CONTRACT
      )
      const transfer = await airdropContract.methods
        .directTransferByAdmin(directTransferAddress, convertMultiPrice(directTransferAmount, 18))
        .send({ from: account }).on("transactionHash", async (transactionHash) => {
          const resp = await axios.post(`${ROOT_URL}/Direct_Transfer`, {
            walletaddress: directTransferAddress,
            amount: convertMultiPrice(directTransferAmount, 18),
            txhash: transactionHash,
            name: name
          })
          getAdminData()
          MessageBox("success", "Direct Transfer Successfully")
        })
    } catch (error) {
      MessageBox("error", error.message)
      console.log(error)
    }
  }

  const onChangeTokenPrice = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    if (account && newPrice) {
      try {
        const airdropContract = loadContract(
          library.provider,
          airdropAbi,
          AIRDROP_CONTRACT
        )
        const tx_change_price = await airdropContract.methods
          .updateTokensPerUSD(convertMultiPrice(newPrice, 18))
          .send({ from: account })
        getAdminData()
        MessageBox("success", "Token Price Updated Successfully")
      } catch (error) {
        MessageBox("error", error.message)
        console.log(error)
      }
    }
  }

  const startStop = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    try {
      const airdropContract = loadContract(
        library.provider,
        airdropAbi,
        AIRDROP_CONTRACT
      )
      if (status) {
        await airdropContract.methods.unpause().send({ from: account })
      } else {
        await airdropContract.methods.pause().send({ from: account })
      }
      const tx_status = await airdropContract.methods.paused().call()
      setStatus(tx_status)
    } catch (error) {
      MessageBox("error", error.message)
      console.log(error)
    }
  }

  const updateAirdropAmount = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    if (account && newAirdropAmount) {
      try {
        const airdropContract = loadContract(
          library.provider,
          airdropAbi,
          AIRDROP_CONTRACT
        )
        const tx_change_price = await airdropContract.methods
          .updateAirdropAmount(convertMultiPrice(newAirdropAmount, 18))
          .send({ from: account })
        getAdminData()
        MessageBox("success", "Airdrop Price Updated Successfully")
      } catch (error) {
        MessageBox("error", error.message)
        console.log(error)
      }
    }
  }

  const updateReffralPercent = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    if (account && updatePercent1 && updatePercent2) {
      try {
        const airdropContract = loadContract(
          library.provider,
          airdropAbi,
          AIRDROP_CONTRACT
        )
        const tx_change_percent = await airdropContract.methods
          .updateReferralPercentage(updatePercent1, updatePercent2)
          .send({ from: account })
        getAdminData()
        MessageBox("success", "Referral Percentage Updated Successfully")
      } catch (error) {
        MessageBox("error", error.message)
        console.log(error)
      }
    }
  }

  const getDashBoardData = async () => {
    setContentLoader(true)
    const w3 = new Web3(library.provider)
    try {
      if (
        account.toLocaleLowerCase() ==
        sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
      ) {
        const tokenContract = loadContract(
          library.provider,
          tokenAbi,
          TOKEN_CONTRACT
        )
        const airdropContract = loadContract(
          library.provider,
          airdropAbi,
          AIRDROP_CONTRACT
        )

        const tx_permatic = await airdropContract.methods.USDPerToken().call()
        setTokensPerMatic(parseFloat(convertPrice(tx_permatic, 18)))

        const maticBal = await w3.eth.getBalance(account)
        const txToken_balance = await tokenContract.methods
          .balanceOf(account)
          .call()
        setBalance({
          matic: convertPrice(maticBal, 18),
          token: convertPrice(txToken_balance, 18),
        })
      }
      setContentLoader(false)
    } catch (e) {
      MessageBox("error", e.message)
      console.log("Error.Contract-token", e)
      setContentLoader(false)
    } finally {
      setContentLoader(false)
    }
  }

  useEffect(() => {
    if (account) {
      getDashBoardData()
    }
  }, [account])

  const onBuyToken = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    if (loading) {
      return
    }
    if (matic && token) {
      if (matic < balance.matic) {
        setLoading(true)
        try {
          const airdropContract = loadContract(
            library.provider,
            airdropAbi,
            AIRDROP_CONTRACT
          )
          const txAirdrop_buy = await airdropContract.methods
            .buyTokens()
            .send({ from: account, value: convertMultiPrice(matic, 18) })
          setLoading(false)
          getDashBoardData()
          MessageBox("success", "Token Buy Successfully")
        } catch (e) {
          setLoading(false)
          MessageBox("error", e.message)
          console.log("Error.buy-token-contract", e)
        }
      } else {
        MessageBox("error", "Insufficient Available Balance")
      }
    } else {
      MessageBox("error", "Please enter amount")
    }
  }

  const airdrop = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet")
      return
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      )
      return
    }
    try {
      const airdropContract = loadContract(
        library.provider,
        airdropAbi,
        AIRDROP_CONTRACT
      )
      const airdrop = await airdropContract.methods
        .airdrop(airdropAddress, refAddress)
        .send({ from: account })
      getAdminData()
      MessageBox("success", "Airdrop Successfully")
    } catch (error) {
      MessageBox("error", error.message)
      console.log(error)
    }
  }


  return (
    <>
      <AdminDashBoardLayout>
        <section className="py-5">
          <Container>
            <Row>
              <Col>
                <h1 className="fs-12 fw-500 font-roboto text-white text-uppercase mb-3">
                  gkc token
                </h1>
              </Col>
            </Row>
            <Row className="tokenaddet p-3 m-0 border rounded d-flex justify-content-between align-items-center">
              <Col>
                <h2 className="m-0 fs-17 font-roboto fw-500 text-white">
                  Token Contract
                </h2>
              </Col>
              <Col className="text-end">
                <a
                  className="m-0 fs-14 font-roboto fw-400 text-white text-decoration-none"
                  href={`${scanerUrl}/address/${tokenContractAddress}`}
                  target="_blank"
                >
                  {truncateEthAddress(tokenContractAddress)}
                </a>
                {<BiCopy style={{ cursor: "pointer" }} onClick={() => copyText(tokenContractAddress)} />}
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-4 pb-md-5">
          <Container>
            <Row>
              <Col xs={12} md={3} className="mb-3 mb-md-0">
                <DataCard
                  title={
                    contentLoader ? (
                      <span className="loading">Loading...</span>
                    ) : adminAddress ? (
                      <span>{getShortAddress(adminAddress)} {<BiCopy style={{ cursor: "pointer" }} onClick={() => copyText(adminAddress)} />}</span>
                    ) : (
                      "0x00"
                    )
                  }
                  subtitle={"Admin"}
                />
              </Col>
              <Col xs={12} md={3} className="mb-3 mb-md-0">
                <DataCard
                  title={
                    contentLoader ? (
                      <span className="loading">Loading...</span>
                    ) : (
                      totalTokensPurchase + " GKC"
                    )
                  }
                  subtitle={"Total Token Purchase"}
                />
              </Col>
              <Col xs={12} md={3} className="mb-3 mb-md-0">
                <DataCard
                  title={
                    contentLoader ? (
                      <span className="loading">Loading...</span>
                    ) : (
                      totalTokensAirdroped + " GKC"
                    )
                  }
                  subtitle={"Total Airdop"}
                />
              </Col>
              <Col xs={12} md={3} className="mb-3 mb-md-0">
                <DataCard
                  title={
                    contentLoader ? (
                      <span className="loading">Loading...</span>
                    ) : (
                      totalTokensAllowted + " GKC"
                    )
                  }
                  subtitle={"Total Token Allotted"}
                />
              </Col>
              <Col md={6} className="mt-3 mt-md-3 ">
                <Card className="data-cards p-0">
                  <Card.Body className="p-3 text-center">
                    <Table striped hover className="mb-0">
                      <thead>
                        <tr className="border-0">
                          <th className="font-roboto text-AmethystSmoke fs-13 fw-400 mt-3 border-0">
                            Refer Level 1
                          </th>
                          <th className="font-roboto text-AmethystSmoke fs-13 fw-400 mt-3 border-0">
                            Refer Level 2
                          </th>
                          <th className="font-roboto text-AmethystSmoke fs-13 fw-400 mt-3 border-0">
                            Total Refer Earning
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-0">
                          <td className="text-capitalize font-roboto fw-400 fs-17 text-white border-0">
                            {contentLoader ? (
                              <span className="loading">Loading...</span>
                            ) : (
                              totalRefLvl1 + " GKC"
                            )}
                          </td>
                          <td className="text-capitalize font-roboto fw-400 fs-17 text-white border-0">
                            {contentLoader ? (
                              <span className="loading">Loading...</span>
                            ) : (
                              totalRefLvl2 + " GKC"
                            )}
                          </td>
                          <td className="text-capitalize font-roboto fw-400 fs-17 text-white border-0">
                            {contentLoader ? (
                              <span className="loading">Loading...</span>
                            ) : (
                              parseFloat(totalRefLvl1) +
                              parseFloat(totalRefLvl2) + " GKC"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={3} className="mt-3 mt-md-3 ">
                <DataCard
                  title={contentLoader ? <span className="loading">Loading...</span>
                    :
                    totalDirectTransfer + " GKC"
                  }
                  subtitle={"Total Direct Transfer"}
                />
              </Col>
              <Col xs={12} md={3} className="mt-3 mt-md-3 ">
                <DataCard
                  title={contentLoader ? <span className="loading">Loading...</span> : totalMinted + " GKC"}
                  subtitle={" total Token Minted"} />
              </Col>
            </Row>
            <Row>
              {/* <Col xs={12} md={3} className="pb-4">
                <DataCard
                  title={contentLoader ? <span className="loading">Loading...</span> : '$ ' + tokensPerMatic}
                  subtitle={'Price Of Token'}
                />
              </Col> */}
            </Row>
          </Container>
        </section>
        <section className="pb-4 pb-md-5">
          <Container>
            <Row className="reffrealcoed p-3 m-0 d-flex justify-content-between align-items-center">
              <Col>
                <h2 className="m-0 fs-11 font-roboto fw-400 text-logan">
                  Buy GKC for referral code
                </h2>
              </Col>
              <Col className="text-end">
                {account ? (
                  account.toLocaleLowerCase() ===
                    sessionStorage
                      .getItem("walletaddress")
                      ?.toLocaleLowerCase() ? (
                    <p className="m-0 fs-11 font-roboto fw-400 text-white">
                      {`${REF_URL}${account}`}
                      {
                        <BiCopy
                          style={{ cursor: "pointer" }}
                          onClick={() => copyText(`${REF_URL}${account}`)}
                          size={20}
                          className="px-1 text-PictonBlue"
                        />
                      }
                      <a
                        href={`https://web.whatsapp.com/send?text=${REF_URL}${account}`}
                        target={"_blank"}
                        className="nav-link d-inline-block px-1 text-PictonBlue"
                      >
                        <BsWhatsapp />
                      </a>
                      <a
                        href={`https://telegram.me/share/url?url=${REF_URL}${account}`}
                        target={"_blank"}
                        className="nav-link d-inline-block px-1 text-PictonBlue"
                      >
                        <BsTelegram />
                      </a>
                      <a
                        href={`http://www.twitter.com/share?url=${REF_URL}${account}`}
                        target={"_blank"}
                        className="nav-link d-inline-block px-1 text-PictonBlue"
                      >
                        <BsTwitter />
                      </a>
                      <a className="nav-link d-inline-block px-1 text-PictonBlue">
                        <FacebookMessengerShareButton
                          appId="521270401588372"
                          quote={`${REF_URL}${account}`}
                          url={DOMAIN_URL}
                        >
                          <BsFacebook />
                        </FacebookMessengerShareButton>
                      </a>
                    </p>
                  ) : (
                    <span className="text-danger">{`Wrong address please connect ${getShortAddress(
                      sessionStorage.getItem("walletaddress")
                    )}`}</span>
                  )
                ) : (
                  "Please connect your wallet to get your REF URL"
                )}
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pb-4 pb-md-5">
          <Container>
            <Row>
              {/* <Col>
                <Card className="reffrealcoed p-5">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-center mb-3">
                      <Col md={7}>
                        <h6>Admin Token Withdrwal</h6>
                      </Col>
                    </Row>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => adminWithdrawal()}
                    >
                      Withdraw
                    </Button>
                  </Card.Body>
                </Card>
              </Col> */}
              <Col md={6}>
                <Card className="reffrealcoed p-4 p-md-5 mb-4 mb-md-0">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-center mb-3">
                      <Col md={12}>
                        <h6>Transfer token in Contract</h6>
                      </Col>
                    </Row>
                    <Form.Label className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan mb-3">
                      GKC token In Contract: {contractBal} GKC
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Number of GKC tokens"
                        aria-label="0.0000"
                        onChange={(event) => {
                          const input = event.target.value
                          const pattern = /^-?\d*\.?\d*$/
                          if (input && pattern.test(input)) {
                            setTransferAmountContract(input)
                          } else {
                            setTransferAmountContract("")
                          }
                        }}
                      />
                    </InputGroup>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => transferTokensInContract()}
                    >
                      Transfer
                    </Button>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => adminWithdrawal()}
                    >
                      Withdraw
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="reffrealcoed p-4 p-md-5 mb-4 mb-md-0">
                  <Card.Body className="p-0">
                    <Form.Label className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan mb-3">
                      {status ? "Unpause" : "Pause"}
                    </Form.Label>
                    <Button
                      variant="info"
                      className="w-100 rounded-pill text-white fw-700"
                      onClick={() => startStop()}
                    >
                      {status ? "Unpause" : "Pause"}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-4 pb-md-5">
          <Container>
            <Row>
              {/* <Col>
                <Card className="reffrealcoed p-5">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-center mb-3">
                      <Col md={12}>
                        <h6>Transfer token in Contract</h6>
                      </Col>
                    </Row>
                    <p className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan">
                      GKC token amount: {tokensInContract}
                    </p>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Number of GKC tokens"
                        aria-label="0.0000"
                        onChange={(event) => {
                          const input = event.target.value;
                          const pattern = /^-?\d*\.?\d*$/;
                          if (input && pattern.test(input)) {
                            setTransferAmountContract(input);
                          } else {
                            setTransferAmountContract('');
                          }
                        }}
                      />
                    </InputGroup>

                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => transferTokensInContract()}
                    >
                      Transfer
                    </Button>
                  </Card.Body>
                </Card>
              </Col> */}

              <Col>
                <Card className="reffrealcoed p-4 p-md-5 mb-3 mb-md-0">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-center mb-3">
                      <Col md={12}>
                        <h6>Change Admin</h6>
                      </Col>
                    </Row>
                    <Form.Label className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan mb-3">
                      Current Admin: {truncateEthAddress(adminAddress)} {<BiCopy style={{ cursor: "pointer" }} onClick={() => copyText(adminAddress)} />}
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="New Admin Address"
                        aria-label="0x000000000000000"
                        onChange={(e) => setNewAdmin(e.target.value)}
                      />
                    </InputGroup>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => changeAdmin()}
                    >
                      Change
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-4 pb-md-5">
          <Container>
            <Row>

              <Col md={6}>
                <Card className="reffrealcoed p-4 p-md-5 mb-3 mb-md-0">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-center mb-3">
                      <Col xs={12}>
                        <h6>Direct Transfer</h6>
                      </Col>
                    </Row>
                    <InputGroup className="mb-3">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="To address"
                          value={directTransferAddress}
                          onChange={(e) =>
                            setDirectTransferAddress(e.target.value)
                          }
                        />
                      </InputGroup>
                      <Form.Control
                        placeholder="Name"
                        value={name}
                        onChange={(e) =>
                          setName(e.target.value)
                        }
                      />
                    </InputGroup>
                    <InputGroup>
                      <Form.Control
                        placeholder="Amount"
                        value={directTransferAmount}
                        onChange={(event) => {
                          const input = event.target.value
                          const pattern = /^-?\d*\.?\d*$/
                          if (input && pattern.test(input)) {
                            setDirectTrasferAmount(input)
                          } else {
                            setDirectTrasferAmount("")
                          }
                        }}
                      />
                    </InputGroup>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => directTransfer()}
                    >
                      Transfer
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="reffrealcoed p-4 p-md-5 mb-3 mb-md-0">
                  <Card.Body className="p-0">
                    <Card.Body className="p-0">
                      <Row className="d-flex justify-content-between align-items-center mb-3">
                        <Col md={7}>
                          <h6>Update Referral Percentage</h6>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Form.Label className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan mb-3">
                        Refferal percent Level1: {percentLevel1} GKC
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Enter Percentage 1"
                          value={updatePercent1}
                          onChange={(event) => {
                            const input = event.target.value
                            const pattern = /^-?\d*\.?\d*$/
                            if (input && pattern.test(input)) {
                              setUpdatePercent1(input)
                            } else {
                              setUpdatePercent1("")
                            }
                          }}
                        />
                      </InputGroup>
                      <Form.Label className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan mb-3">
                        Refferal percent Level2: {percentLevel2} GKC
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Enter Percentage 2"
                          value={updatePercent2}
                          onChange={(event) => {
                            const input = event.target.value
                            const pattern = /^-?\d*\.?\d*$/
                            if (input && pattern.test(input)) {
                              setUpdatePercent2(input)
                            } else {
                              setUpdatePercent2("")
                            }
                          }}
                        />
                      </InputGroup>
                      <Button
                        variant="info"
                        className="w-100 mt-4 rounded-pill text-white fw-700"
                        onClick={() => updateReffralPercent()}
                      >
                        Update
                      </Button>
                    </Card.Body>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-4 pb-md-5">
          <Container>
            <Row>
              <Col md={6}>
                <Card className="reffrealcoed p-4 p-md-5 mb-3 mb-md-0">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-center mb-3">
                      <Col xs={12}>
                        <h6>Update Airdrop Amount</h6>
                      </Col>
                    </Row>
                    <Form.Label className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan mb-3">
                      Current Airdrop Amount: {airdropAmt} GKC
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="New Amount"
                        value={newAirdropAmount}
                        onChange={(event) => {
                          const input = event.target.value
                          const pattern = /^-?\d*\.?\d*$/
                          if (input && pattern.test(input)) {
                            setNewAirdropAmount(input)
                          } else {
                            setNewAirdropAmount("")
                          }
                        }}
                      />
                    </InputGroup>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => updateAirdropAmount()}
                    >
                      Update Amount
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="reffrealcoed p-4 p-md-5 mb-3 mb-md-0">
                  <Card.Body className="p-0">
                    <Card.Body className="p-0">
                      <Row className="d-flex justify-content-between align-items-center mb-3">
                        <Col xs={12}>
                          <h6>Change Token Price</h6>
                        </Col>
                      </Row>
                      <Form.Label className="text-start text-uppercase fs-14 fw-400 font-roboto text-logan mb-3">
                        Current Token Price: {"$ " + tokensPerMatic}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Enter New Price"
                          value={newPrice}
                          onChange={(event) => {
                            const input = event.target.value
                            const pattern = /^-?\d*\.?\d*$/
                            if (input && pattern.test(input)) {
                              setNewPrice(input)
                            } else {
                              setNewPrice("")
                            }
                          }}
                        />
                      </InputGroup>
                      <Button
                        variant="info"
                        className="w-100 mt-4 rounded-pill text-white fw-700"
                        onClick={() => onChangeTokenPrice()}
                      >
                        Change
                      </Button>
                    </Card.Body>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <div id="BuyGKC_token"></div>
        <section className="pb-5">
          <Container>
            <Row>
              <Col xs={12}>
                <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                  BUY token
                </h5>
                <Card className="reffrealcoed p-4 p-md-5">
                  <Card.Body className="p-0">
                    {!account && (
                      <Row className="d-flex justify-content-between align-items-center mb-3">
                        <Col md={7}>
                          <h6>Connect your wallet to buy token</h6>
                        </Col>
                        <Col></Col>
                      </Row>
                    )}
                    <p className="text-end text-uppercase fs-14 fw-400 font-roboto text-logan">
                      Bal:{" "}
                      {contentLoader ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        balance.token
                      )}{" "}
                      GKC
                    </p>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="0.0000"
                        aria-label="0.0000"
                        value={token}
                        onChange={(event) => {
                          const input = event.target.value
                          const pattern = /^-?\d*\.?\d*$/
                          if (input && pattern.test(input)) {
                            setToken(input)
                            setMatic(parseFloat(input) * tokensPerMatic)
                          } else {
                            setMatic("")
                            setToken("")
                          }
                        }}
                      />
                      <Button variant="outline-secondary" className="px-4">
                        GKC
                      </Button>
                    </InputGroup>
                    <p className="text-end text-uppercase fs-14 fw-400 font-roboto text-logan">
                      Bal:{" "}
                      {contentLoader ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        balance.matic
                      )}{" "}
                      MATIC
                    </p>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="0.0000"
                        aria-label="0.0000"
                        value={matic}
                        onChange={(event) => {
                          const input = event.target.value
                          const pattern = /^-?\d*\.?\d*$/
                          if (input && pattern.test(input)) {
                            setMatic(input)
                            setToken(parseFloat(input) / tokensPerMatic)
                          } else {
                            setMatic("")
                            setToken("")
                          }
                        }}
                      />
                      <Button variant="outline-secondary" className="px-4">
                        MATIC
                      </Button>
                    </InputGroup>
                    <div className="text-center mt-4 mb-3">
                      <TbArrowsUpDown size={20} />
                    </div>
                    <Button
                      variant="info"
                      className="w-100 mt-4 rounded-pill text-white fw-700"
                      onClick={() => onBuyToken()}
                    >
                      {loading ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        "BUY"
                      )}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col>

                <Col md={6}>
                  <Card className="reffrealcoed p-4 p-md-5 mb-3 mb-md-0">
                    <Card.Body className="p-0">
                      <Row className="d-flex justify-content-between align-items-center mb-3">
                        <Col xs={12}>
                          <h6>Airdrop</h6>
                        </Col>
                      </Row>
                      <InputGroup className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Airdrop address"
                            value={airdropAddress}
                            onChange={(e) =>
                              setAirdropAddress(e.target.value)
                            }
                          />
                        </InputGroup>
                        <Form.Control
                          placeholder="Ref address"
                          value={refAddress}
                          onChange={(e) =>
                            setRefAddress(e.target.value)
                          }
                        />
                      </InputGroup>
                      <Button
                        variant="info"
                        className="w-100 mt-4 rounded-pill text-white fw-700"
                        onClick={() => airdrop()}
                      >
                        Airdrop
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-4 pb-md-5">
          <Container>
            <Row>
              <Col>
                <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                  airdrop status
                </h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between align-items-center">
              <Col>
                <Card className="reffrealcoed p-4">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-center">
                      <Col md={6} className="text-center">
                        <Image
                          src={Airdrop}
                          alt=""
                          className="img-fluid w-75"
                        />
                      </Col>
                      <Col md={6}>
                        <div className="d-block justify-content-start align-items-center d-md-flex justify-content-md-start align-items-md-center mb-5">
                          <div className="pe-0 pe-md-5 border-end">
                            <h2 className="fw-500 font-roboto fs-30 text-white">
                              {10000000 - totalTokensAllowted}
                            </h2>
                            <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
                              Airdrop Give Away GKC Tokens
                            </p>
                          </div>
                          <div className="ps-0 ps-md-5 mt-3 mt-md-0">
                            <h2 className="fw-500 font-roboto fs-30 text-white">
                              {"1,00,00,000"}
                            </h2>
                            <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
                              Tokens Avaialbe in Airdrop for Round 1
                            </p>
                          </div>
                        </div>
                        <div>
                          <h6 className="text-white fw-500 fs-14 font-outfit">
                            Join and earn free airdrop:
                          </h6>
                          <ul className="text-logan fw-400 fs-11 font-roboto ps-3 mb-0">
                            <li>
                              Signup to avail of Airdrop 100 GKC Token Worth 5.3
                              USD
                            </li>
                            <li>
                              Connect your Wallet with the Polygon network
                            </li>
                            <li>Claim your Daily Minted token from Dapp</li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </AdminDashBoardLayout>
    </>
  )
}

export default AdminDashboard
