import React from "react";
import { Image, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/GKC_Logo.png";
import { BsGrid1X2Fill } from "react-icons/bs";
import { MdAccountBalanceWallet } from "react-icons/md";
import { HiPaperAirplane } from "react-icons/hi";
import { TiUser } from "react-icons/ti";
import { IoMdSettings } from "react-icons/io";

const UserDashboardSidebar = () => {
  return (
    <>
      <aside className="user-dash-siedebar">
        <div className="text-center">
          <Image src={Logo} className="img-fluid mb-5" alt="" />
        </div>
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to="/user-dashboard"
          >
            <BsGrid1X2Fill className="me-2" /> Dashboard
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to="/user-buy"
          >
            <MdAccountBalanceWallet className="me-2" /> Buy
          </NavLink>
          <Link className="nav-link" to="#">
            <HiPaperAirplane className="me-2" /> Airdrop
          </Link>
          {/* <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to="/user-account-setting"
          >
            <IoMdSettings className="me-2" /> Account Setting
          </NavLink> */}
          <NavLink
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            to="/user-profile"
          >
            <TiUser className="me-2" /> My Profile
          </NavLink>
        </Nav>
        <div className="p-5"></div>
      </aside>
    </>
  );
};

export default UserDashboardSidebar;
