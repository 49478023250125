import React from "react";
import {
  Navbar,
  Container,
  Form,
  Button,
  Nav,
  NavDropdown,
  Dropdown,
  Image,
} from "react-bootstrap";
import Logo from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../components/connectWallet/useAuth";
import { injected } from "../components/connectWallet/connectors";
import { getShortAddress } from "../components/constant";

const Header = () => {
  const { active, account } = useWeb3React();
  const { login } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <header className="webheader">
        {" "}
        <Navbar expand="lg" sticky="top">
          <Container>
            <Navbar.Brand href="/">
              <Image src={Logo} className="img-fluid" alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="ms-auto my-2 my-lg-0"
                style={{ maxHeight: "500px" }}
                navbarScroll
              >
                <Link to="/about" className="nav-link">
                  about
                </Link>
                <Link to="/service" className="nav-link">
                  Services
                </Link>
                <Link to="/gkc-token" className="nav-link">
                  GKC Token
                </Link>
                <Link to="/Whitepaper" className="nav-link">
                  Whitepaper
                </Link>
                {/* <Link to="/user-dashboard" className="nav-link">
                  Dapp
                </Link> */}
                {/* <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-success"
                    id="dropdown-basic"
                    className="btn-primary-custom me-3"
                  >
                    Links
                  </Dropdown.Toggle>
                </Dropdown> */}
              </Nav>
              <Form className="d-flex">
                {/* <Link
                  to="/claim-token"
                  variant="outline-secondary"
                  className="btn-primary-custom text-decoration-none d-inline-block"
                >
                  Claim Token
                </Link> */}
                {/* <Link
                  to="/signup"
                  variant="outline-secondary"
                  className="btn-primary-custom text-decoration-none d-lg-inline-block"
                >
                  Sign Up
                </Link> */}
                {/* <Link
                  to="/signin"
                  variant="outline-secondary"
                  className="btn-primary-custom text-decoration-none d-lg-inline-block ms-3"
                >
                  Sign In
                </Link> */}
                <Button
                  variant="outline-success"
                  className="btn-primary-custom ms-3"
                  onClick={() => {
                    if (!sessionStorage.getItem("email") || !account) {
                      navigate("/signin");
                    }
                  }}
                >
                  {sessionStorage.getItem("email") && account
                    ? getShortAddress(account)
                    : "Dapp"}
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
