import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/GKC_Logo.png";
import { MessageBox } from "../../components/MessageBox";
import axios from "axios";
import { ROOT_URL } from "../../components/constant";
import UserAuthLayout from "../layout/UserAuthLayout";

const ResetPassword = () => {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validation, setValidation] = useState({ pwd1: false, pwd2: false });
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate('/forgot-password')
    }
  }, [])

  const checkValidation = () => {
    if (loading) {
      return;
    }
    if (!/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(password)) {
      setValidation({ ...validation, pwd1: true });
      return;
    }
    if (password != confirmPassword) {
      setValidation({ ...validation, pwd2: true });
      return;
    }
    onResetPassword();
  };

  const onResetPassword = async () => {  
    setLoading(true)
    axios({
      method: 'post',
      url: `${ROOT_URL}/Change_Password`,
      data: {
        user_data: location.state ? location.state.user_data : '',
        password: password,
      }
    }).then(res => {
      setLoading(false);
      if (res.data.code == 200) {
        MessageBox('success', res.data.message);
        navigate('/signin');
      } else {
        MessageBox('error', res.data.message);
      }
    }).catch(err => {
      setLoading(false);
      console.log('Error.reset-password', err);
    });
  }

  return (
    <>
      <UserAuthLayout>
        <section className="auth-main">
          <Container>
            <Row className="d-flex justify-content-center align-items-center text-center pb-5">
              <Col md={7} lg={5}>
                <div className="sign-card p-5 rounded mt-5">
                  <Image src={logo} alt="" className="img-fluid mb-4" />

                  <h2 className="font-outfit fw-700 text-white text-capitalize mb-4">
                    Reset Password
                  </h2>
                  <p className="font-roboto fw-400 text-logan fs-18 mb-3">
                    Enter your new password
                  </p>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value)
                          setValidation({ ...validation, pwd1: false })
                        }}
                      />
                      {
                        validation.pwd1 &&
                        <label className="form-label m-0 text-danger">
                          {password ? 'Please enter strong password' : 'Please enter password'}
                        </label>
                      }
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="Conform Password"
                        value={confirmPassword}
                        onChange={(event) => {
                          setConfirmPassword(event.target.value)
                          setValidation({ ...validation, pwd2: false })
                        }}
                      />
                      {
                        validation.pwd2 &&
                        <label className="form-label m-0 text-danger">
                          {validation.pwd2 && confirmPassword ? 'Password does not match' : 'Please enter confirm password'}
                        </label>
                      }
                    </Form.Group>
                    <Button
                      variant="outline-success"
                      size="lg"
                      className="btn-primary-custom w-100 py-3 mb-3"
                      onClick={() => { checkValidation() }}
                    >
                      {loading ? "Loading..." : "Reset Password"}
                    </Button>
                  </Form>
                  <div>
                    <Link
                      to="/signin"
                      className="font-roboto fw-400 text-logan fs-18 text-decoration-none"
                    >
                      Back to Sign In?
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </UserAuthLayout>
    </>
  );
};

export default ResetPassword;
