import React, { useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import UserAuthLayout from "../layout/UserAuthLayout";
import logo from "../../assets/images/GKC_Logo.png";
import axios from "axios";
import { ROOT_URL } from "../../components/constant";
import { MessageBox } from "../../components/MessageBox";

const ForgotPassword = () => {

  const [email, setEmail] = useState('');
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState('');
  const navigate = useNavigate();

  const checkValidation = () => {
    if (loading) {
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      setValidation(true);
      return;
    }
    onSendOTP();
  }

  const onSendOTP = () => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${ROOT_URL}/Send_Otp`,
      data: {
        user_data: email
      }
    }).then(res => {
      setLoading(false);
      if (res.data.code == 200) {
        MessageBox('success', res.data.message);
        navigate('/sent-otp', { state: { user_data: email } });
      } else {
        MessageBox('error', res.data.message);
      }
    }).catch(err => {
      setLoading(false);
      console.log("Error.forgot-password", err);
    });
  }

  return (
    <>
      <UserAuthLayout>
        <section className="auth-main py-5">
          <Container>
            <Row className="d-flex justify-content-center align-items-center text-center pb-5">
              <Col md={7} lg={5}>
                <div className="sign-card p-5 rounded mt-5">
                  <Image src={logo} alt="" className="img-fluid mb-4" />
                  <h2 className="font-outfit fw-700 text-white text-capitalize mb-4">
                    forgot password
                  </h2>
                  <p className="font-roboto fw-400 text-logan fs-18 mb-3">
                    Enter your email address
                  </p>
                  <Form>
                    <Form.Group className="mb-3 d-lg-flex">
                      <Form.Control
                        type="text"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setValidation(false);
                        }}
                      />
                      {
                        validation &&
                        <label className="form-label m-0 text-danger">
                          {email ? 'Invalid email address' : 'Please enter email address'}
                        </label>
                      }
                    </Form.Group>
                    <Link
                      to="/forgot-password"
                      variant="outline-success"
                      size="lg"
                      className="d-block btn-primary-custom w-100 py-3 text-decoration-none"
                      onClick={() => checkValidation()}
                    >
                      {loading ? 'Loading...' : 'Send OTP'}
                    </Link>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </UserAuthLayout>
    </>
  );
};

export default ForgotPassword;
