import React from "react";
import UserDashBoardLayout from "../layout/UserDashBoardLayout";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
const UserAccountSetting = () => {
  return (
    <>
      <UserDashBoardLayout>
        <section className="py-4 py-md-5">
          <Container>
            <Row>
              <Col>
                <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                  Account Setting
                </h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between align-items-center">
              <Col>
                <Card className="reffrealcoed p-4">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-start">
                      <Col
                        md={5}
                        className="d-flex justify-content-end align-items-start"
                      ></Col>
                      <Col xs={12}>
                        <Row>
                          <Form.Group
                            as={Col}
                            md={3}
                            className="mb-3 mb-md-0"
                            controlId="formGridEmail"
                          >
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Pranay Masulkar"
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md={3}
                            className="mb-3 mb-md-0"
                            controlId="formGridEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="pranay@gmail.com"
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md={3}
                            className="mb-3 mb-md-0"
                            controlId="formGridEmail"
                          >
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="8593859967"
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md={3}
                            className="mb-3 mb-md-0"
                            controlId="formGridEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                            />
                          </Form.Group>
                        </Row>

                        <div className="mt-4">
                          <h6 className="text-white fw-500 fs-14 font-outfit">
                            Join and earn free airdrop:
                          </h6>
                          <ul className="text-logan fw-400 fs-11 font-roboto ps-3 mb-0">
                            <li>
                            Signup to avail of Airdrop 100 GKC Token Worth 5.3 USD
	                            </li>
                            <li>
                            Connect your Wallet with the Polygon network
	                            </li>
                            <li>
                            Claim your Daily Minted token from Dapp
                            </li>
                            
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </UserDashBoardLayout>
    </>
  );
};

export default UserAccountSetting;
